import useGitFeatureFlags from "git/hooks/useGitFeatureFlags";
import React from "react";
import CDLicensed from "./CDLicensed";
import CDUnLicensed from "./CDUnLicensed";

function ContinuousDelivery() {
  const { license_git_continuous_delivery_enabled } = useGitFeatureFlags();

  if (license_git_continuous_delivery_enabled) {
    return <CDLicensed />;
  }

  return <CDUnLicensed />;
}

export default ContinuousDelivery;
