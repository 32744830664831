import { createArtifactAction } from "git/store/helpers/createArtifactAction";
import type { GitAsyncErrorPayload } from "git/store/types";

export const toggleCDInitAction = createArtifactAction((state) => {
  state.apiResponses.toggleCD.loading = true;
  state.apiResponses.toggleCD.error = null;

  return state;
});

export const toggleCDSuccessAction = createArtifactAction((state) => {
  state.apiResponses.toggleCD.loading = false;
  state.apiResponses.toggleCD.error = null;

  return state;
});

export const toggleCDErrorAction = createArtifactAction<GitAsyncErrorPayload>(
  (state, action) => {
    const { error } = action.payload;

    state.apiResponses.toggleCD.loading = false;
    state.apiResponses.toggleCD.error = error;

    return state;
  },
);
