import { fetchPackage } from "ee/actions/packageActions";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import {
  getCurrentBasePackageId,
  getCurrentPackage,
} from "ee/selectors/packageSelectors";
import { GitArtifactType } from "git/constants/enums";
import type { ConnectResponseData } from "git/requests/connectRequest.types";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import type {
  GitArtifactPayloadAction,
  GitAsyncSuccessPayload,
} from "git/store/types";
import type { GitPackageArtifact } from "git/types";
import { put, select, take } from "redux-saga/effects";

export default function* packageConnectToGitSaga(
  action: GitArtifactPayloadAction<GitAsyncSuccessPayload<ConnectResponseData>>,
) {
  const { artifactDef } = action.payload;

  if (artifactDef.artifactType !== GitArtifactType.Package) return;

  const basePackageId: string = yield select(getCurrentBasePackageId);

  yield put(fetchPackage({ basePackageId }));
  yield take(ReduxActionTypes.FETCH_PACKAGE_SUCCESS);

  const currentPackage: GitPackageArtifact = yield select(getCurrentPackage);

  yield put(
    gitArtifactActions.initGitForEditor({
      artifactDef,
      artifact: currentPackage,
    }),
  );
}
