export * from "ce/reducers/entityReducers/jsActionsReducer";
import type { FetchModuleEntitiesResponse } from "ee/api/ModuleApi";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { JSCollectionDataState } from "ce/reducers/entityReducers/jsActionsReducer";
import {
  handlers as CE_handlers,
  initialState as CE_initialState,
} from "ce/reducers/entityReducers/jsActionsReducer";
import { klona } from "klona/json";
import { createReducer } from "utils/ReducerUtils";

const initialState = CE_initialState;

const handlers = {
  ...CE_handlers,
  [ReduxActionTypes.FETCH_MODULE_ENTITIES_SUCCESS]: (
    state: JSCollectionDataState,
    action: ReduxAction<FetchModuleEntitiesResponse>,
  ) => {
    const result: JSCollectionDataState = [];

    action.payload.jsCollections.forEach((action) => {
      result.push({
        isLoading: false,
        config: action.isPublic
          ? {
              // without klona, the action here becomes an inextensible object and throws error
              // TypeError: Cannot delete property '1' of [object Array] JSPaneUtils.tsx:141:1
              ...klona(action),
              isMainJSCollection: true,
              displayName: "Main",
            }
          : action,
        data: undefined,
      });
    });

    return result;
  },
};

const jsActionsReducer = createReducer(initialState, handlers);

export default jsActionsReducer;
