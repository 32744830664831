import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export const setPkgIDEJSTabs = (tabs: string[], parentId: string) => {
  return {
    type: ReduxActionTypes.SET_PACKAGE_IDE_JS_TABS,
    payload: { tabs, parentId },
  };
};

export const setPkgIDEQueryTabs = (tabs: string[], parentId: string) => {
  return {
    type: ReduxActionTypes.SET_PACKAGE_IDE_QUERIES_TABS,
    payload: { tabs, parentId },
  };
};

export const closeJSModuleActionTab = (payload: {
  id: string;
  parentId: string;
}) => {
  return {
    type: ReduxActionTypes.CLOSE_JS_MODULE_ACTION_TAB,
    payload,
  };
};
export const closeJSModuleActionTabSuccess = (payload: {
  id: string;
  parentId: string;
}) => {
  return {
    type: ReduxActionTypes.CLOSE_JS_MODULE_ACTION_TAB_SUCCESS,
    payload,
  };
};

export const closeQueryModuleActionTab = (payload: {
  id: string;
  parentId: string;
}) => {
  return {
    type: ReduxActionTypes.CLOSE_QUERY_MODULE_ACTION_TAB,
    payload,
  };
};
export const closeQueryModuleActionTabSuccess = (payload: {
  id: string;
  parentId: string;
}) => {
  return {
    type: ReduxActionTypes.CLOSE_QUERY_MODULE_ACTION_TAB_SUCCESS,
    payload,
  };
};
