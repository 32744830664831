import { createArtifactAction } from "git/store/helpers/createArtifactAction";
import type { GitAsyncErrorPayload } from "git/store/types";

export interface UpdateDefaultBranchInitPayload {
  branchName: string;
}

export const updateDefaultBranchInitAction =
  createArtifactAction<UpdateDefaultBranchInitPayload>((state) => {
    state.apiResponses.updateDefaultBranch.loading = true;
    state.apiResponses.updateDefaultBranch.error = null;

    return state;
  });

export const updateDefaultBranchSuccessAction = createArtifactAction(
  (state) => {
    state.apiResponses.updateDefaultBranch.loading = false;
    state.apiResponses.updateDefaultBranch.error = null;

    return state;
  },
);

export const updateDefaultBranchErrorAction =
  createArtifactAction<GitAsyncErrorPayload>((state, action) => {
    const { error } = action.payload;

    state.apiResponses.updateDefaultBranch.loading = false;
    state.apiResponses.updateDefaultBranch.error = error;

    return state;
  });
