import {
  gitCheckoutBranchSuccess,
  gitConnectSuccess,
  gitDiscardSuccess,
  gitImportSuccess,
  gitPullSuccess,
} from "git/store";
import { all, takeLatest } from "redux-saga/effects";
import packageRedirectToClosestEntitySaga from "./packageRedirectToClosestEntitySaga";
import packageConnectToGitSaga from "./packageConnectToGitSaga";
import packageImportFromGitSaga from "./packageImportFromGitSaga";

export default function* gitPackageSagas() {
  yield all([
    takeLatest(gitConnectSuccess.type, packageConnectToGitSaga),
    takeLatest(gitImportSuccess.type, packageImportFromGitSaga),
    takeLatest(gitDiscardSuccess.type, packageRedirectToClosestEntitySaga),
    takeLatest(
      gitCheckoutBranchSuccess.type,
      packageRedirectToClosestEntitySaga,
    ),
    takeLatest(gitPullSuccess.type, packageRedirectToClosestEntitySaga),
  ]);
}
