export * from "ce/pages/AppIDE/layout/routers/utils/getQueryEntityItemUrl";

import { getQueryEntityItemUrl as CE_getQueryEntityItemUrl } from "ce/pages/AppIDE/layout/routers/utils/getQueryEntityItemUrl";
import type { EntityItem } from "ee/IDE/Interfaces/EntityItem";
import { moduleInstanceEditorURL } from "ee/RouteBuilder";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";

export const getQueryEntityItemUrl = (
  item: EntityItem,
  basePageId: string,
): string => {
  if (item.isModuleInstance) {
    return moduleInstanceEditorURL({
      basePageId,
      moduleType: MODULE_TYPE.QUERY,
      moduleInstanceId: item.key,
    });
  }

  return CE_getQueryEntityItemUrl(item, basePageId);
};
