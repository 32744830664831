export * from "git/ce/store/selectors/gitArtifactSelectors";

import {
  selectGitArtifact,
  selectMetadataState,
} from "git/store/selectors/gitArtifactSelectors";
import type { GitRootState } from "git/store/types";
import type { GitArtifactDef } from "git/types";

export const selectUpdateDefaultBranchState = (
  state: GitRootState,
  artifactDef: GitArtifactDef,
) => selectGitArtifact(state, artifactDef)?.apiResponses?.updateDefaultBranch;

export const selectCDEnabled = (
  state: GitRootState,
  artifactDef: GitArtifactDef,
) =>
  selectMetadataState(state, artifactDef)?.value?.isAutoDeploymentEnabled ??
  false;

export const selectToggleCDState = (
  state: GitRootState,
  artifactDef: GitArtifactDef,
) => selectGitArtifact(state, artifactDef)?.apiResponses?.toggleCD;

export const selectGenerateCDApiKeyState = (
  state: GitRootState,
  artifactDef: GitArtifactDef,
) => selectGitArtifact(state, artifactDef)?.apiResponses?.generateCDApiKey;

export const selectDisableCDModalOpen = (
  state: GitRootState,
  artifactDef: GitArtifactDef,
) => selectGitArtifact(state, artifactDef)?.ui.cdDisableModalOpen;

export const selectReconfigureCDModalOpen = (
  state: GitRootState,
  artifactDef: GitArtifactDef,
) => selectGitArtifact(state, artifactDef)?.ui.cdReconfigureModalOpen;
