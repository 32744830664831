export * from "ce/reducers/entityReducers/canvasWidgetsStructureReducer";
import { createImmerReducer } from "utils/ReducerUtils";
import {
  initialState,
  handlers as CE_handlers,
  type CanvasWidgetsStructureReduxState,
} from "ce/reducers/entityReducers/canvasWidgetsStructureReducer";
import { denormalize } from "utils/canvasStructureHelpers";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { UpdateCanvasPayload } from "actions/pageActions";

const denormalizeCanvasWidgets = (
  draftState: CanvasWidgetsStructureReduxState,
  action: ReduxAction<UpdateCanvasPayload>,
) => {
  return denormalize("0", action.payload.widgets);
};

const handlers = {
  ...CE_handlers,

  [ReduxActionTypes.CREATE_UI_MODULE_SUCCESS]: denormalizeCanvasWidgets,
  [ReduxActionTypes.INIT_MODULE_CANVAS_LAYOUT]: denormalizeCanvasWidgets,
  [ReduxActionTypes.UPDATE_MODULE_LAYOUT_SUCCESS]: denormalizeCanvasWidgets,
};

const canvasWidgetsStructureReducer = createImmerReducer(
  initialState,
  handlers,
);

export default canvasWidgetsStructureReducer;
