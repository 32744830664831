import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { RagDocument } from "ee/components/formControls/Rag/types";

export const setRagDocuments = (payload: {
  [kye: string]: RagDocument[];
}): ReduxAction<{ [kye: string]: RagDocument[] }> => ({
  type: ReduxActionTypes.SET_RAG_DOCUMENTS,
  payload,
});

export const updateSelectedRagDocumentsInit = () => ({
  type: ReduxActionTypes.UPDATE_SELECTED_RAG_DOCUMENTS_INIT,
});

export const updateSelectedRagDocumentsSuccess = () => ({
  type: ReduxActionTypes.UPDATE_SELECTED_RAG_DOCUMENTS_SUCCESS,
});
