import { call, put, select } from "redux-saga/effects";

import CodemirrorTernService from "utils/autocomplete/CodemirrorTernService";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import { isAirgapped } from "ee/utils/airgapHelpers";
import { fetchPluginFormConfigs, fetchPlugins } from "actions/pluginActions";
import {
  fetchDatasources,
  fetchMockDatasources,
} from "actions/datasourceActions";
import { failFastApiCalls } from "sagas/InitSagas";
import {
  PluginFormConfigsNotFoundError,
  PluginsNotFoundError,
} from "entities/Engine";
import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  fetchJSLibrariesForPkg,
  fetchPackage,
} from "ee/actions/packageActions";
import { setAppMode } from "actions/pageActions";
import { APP_MODE } from "entities/App";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import { getCurrentPackage } from "ee/selectors/packageSelectors";
import type { Package } from "ee/constants/PackageConstants";
import { packageArtifact } from "git-artifact-helpers/ee/packages";
import { fetchPackageSaga } from "ee/sagas/packagesSagas";
import type { FetchPackageResponse } from "ee/api/PackageApi";
import { selectGitPackageEnabled } from "ee/selectors/moduleFeatureSelectors";

export default class PackageEditorEngine {
  *loadPackage(basePackageId: string, branchName?: string) {
    const response: FetchPackageResponse | undefined = yield call(
      fetchPackageSaga,
      fetchPackage({ basePackageId, branchName }),
    );
    const pkg = response?.packageData;

    // do not remove, might be a solution to race condition issue b/w fetchPackage and setupModule
    // yield put(fetchPackage({ basePackageId, branchName }));
    // yield take(ReduxActionTypes.FETCH_PACKAGE_SUCCESS);
    // const pkg: Package = yield select(getPackageByBaseId, basePackageId);

    if (pkg) {
      yield put({
        type: ReduxActionTypes.SET_CURRENT_WORKSPACE_ID,
        payload: {
          workspaceId: pkg.workspaceId,
          editorId: pkg.id,
        },
      });

      yield put({
        type: ReduxActionTypes.SET_CURRENT_PACKAGE_ID,
        payload: pkg,
      });
    }
  }

  public *setupEngine() {
    // Ideally this should be modified to EditorMode from AppMode
    // to consider all editors but that requires some refactoring
    yield put(setAppMode(APP_MODE.EDIT));
    yield put({ type: ReduxActionTypes.START_EVALUATION });
    CodemirrorTernService.resetServer();
  }

  *loadPluginsAndDatasources() {
    const isAirgappedInstance = isAirgapped();
    const initActions: ReduxAction<unknown>[] = [
      fetchPlugins(),
      fetchDatasources(),
      fetchJSLibrariesForPkg(),
    ];

    const successActions = [
      ReduxActionTypes.FETCH_PLUGINS_SUCCESS,
      ReduxActionTypes.FETCH_DATASOURCES_SUCCESS,
    ];

    const errorActions = [
      ReduxActionErrorTypes.FETCH_PLUGINS_ERROR,
      ReduxActionErrorTypes.FETCH_DATASOURCES_ERROR,
    ];

    if (!isAirgappedInstance) {
      initActions.push(fetchMockDatasources());
      successActions.push(ReduxActionTypes.FETCH_MOCK_DATASOURCES_SUCCESS);
      errorActions.push(ReduxActionErrorTypes.FETCH_MOCK_DATASOURCES_ERROR);
    }

    const initActionCalls: boolean = yield call(
      failFastApiCalls,
      initActions,
      successActions,
      errorActions,
    );

    if (!initActionCalls)
      throw new PluginsNotFoundError("Unable to fetch plugins");

    const pluginFormCall: boolean = yield call(
      failFastApiCalls,
      [fetchPluginFormConfigs()],
      [ReduxActionTypes.FETCH_PLUGIN_FORM_CONFIGS_SUCCESS],
      [ReduxActionErrorTypes.FETCH_PLUGIN_FORM_CONFIGS_ERROR],
    );

    if (!pluginFormCall)
      throw new PluginFormConfigsNotFoundError(
        "Unable to fetch plugin form configs",
      );
  }

  *loadGit() {
    const isGitPackageEnabled: boolean = yield select(selectGitPackageEnabled);

    if (!isGitPackageEnabled) return;

    const currentPackage: Package = yield select(getCurrentPackage);

    yield put(
      gitArtifactActions.initGitForEditor({
        artifactDef: packageArtifact(currentPackage.baseId),
        artifact: currentPackage,
      }),
    );
  }

  *completeChore() {
    yield put({
      type: ReduxActionTypes.INITIALIZE_PACKAGE_EDITOR_SUCCESS,
    });
  }
}
