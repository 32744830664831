import { currentPackageEditorURL } from "ee/RouteBuilder";
import { call, put, select } from "redux-saga/effects";
import history from "utils/history";
import PackageApi, { type FetchPackageResponse } from "ee/api/PackageApi";
import type { ApiResponse } from "api/types";
import urlBuilder from "ee/entities/URLRedirect/URLAssembly";
import { initPackageEditor } from "ee/actions/packageInitActions";
import type {
  GitArtifactPayloadAction,
  GitAsyncSuccessPayload,
} from "git/store/types";
import type { GitPackageArtifact } from "git/types";
import { getCurrentBaseModuleId } from "ee/selectors/modulesSelector";
import { GitArtifactType } from "git/constants/enums";

export default function* packageRedirectToClosestEntitySaga(
  action: GitArtifactPayloadAction<GitAsyncSuccessPayload<GitPackageArtifact>>,
) {
  const { artifactDef, responseData: destArtifact } = action.payload;

  if (artifactDef.artifactType !== GitArtifactType.Package) return;

  const basePackageId = destArtifact?.baseId ?? "";
  const branchName = destArtifact?.gitArtifactMetadata?.branchName ?? "";

  const response: ApiResponse<FetchPackageResponse> = yield call(
    PackageApi.fetchPackage,
    { basePackageId, branchName },
  );

  const baseModuleId: string = yield select(getCurrentBaseModuleId);

  const moduleExists = response.data.modules.find(
    (module) => module.baseId === baseModuleId,
  );
  const defaultModule = response.data.modules[0];

  if (!moduleExists && defaultModule) {
    urlBuilder.setCurrentBaseModuleId(defaultModule.baseId);
    history.push(
      currentPackageEditorURL({
        baseModuleId: defaultModule.baseId,
        branch: branchName ?? undefined,
      }),
    );
  } else if (!moduleExists && !defaultModule) {
    urlBuilder.setCurrentBaseModuleId(undefined);
  }

  yield put(initPackageEditor({ basePackageId, branchName }));
}
