import Api from "api/Api";
import type { ApiResponse } from "api/types";
import type { AxiosPromise } from "axios";
import type { GitArtifactType } from "git/constants/enums";
import { GIT_BASE_URL } from "git/requests/constants";

export type ToggleCDResponseData = boolean;

export type ToggleCDResponse = ApiResponse<ToggleCDResponseData>;

async function toggleCDRequestOld(
  baseApplicationId: string,
): AxiosPromise<ToggleCDResponse> {
  return Api.patch(
    `${GIT_BASE_URL}/auto-deployment/toggle/app/${baseApplicationId}`,
  );
}

async function toggleCDRequestNew(
  artifactType: GitArtifactType,
  baseArtifactId: string,
): AxiosPromise<ToggleCDResponse> {
  return Api.patch(
    `${GIT_BASE_URL}/${artifactType}/${baseArtifactId}/auto-deployment/toggle`,
  );
}

export default async function toggleCDRequest(
  artifactType: GitArtifactType,
  baseArtifactId: string,
  isNew: boolean,
): AxiosPromise<ToggleCDResponse> {
  if (isNew) {
    return toggleCDRequestNew(artifactType, baseArtifactId);
  } else {
    return toggleCDRequestOld(baseArtifactId);
  }
}
