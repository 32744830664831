import {
  blockingActionSagas as blockingActionSagasCE,
  nonBlockingActionSagas as nonBlockingActionSagasCE,
} from "git/ce/sagas";

import type { PayloadAction } from "@reduxjs/toolkit";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import updateDefaultBranchSaga from "./updateDefaultBranchSaga";
import { toggleCDSaga } from "./toggleCDSaga";
import { generateCDApiKeySaga } from "./generateCDApiKeySaga";

export const blockingActionSagas: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (action: PayloadAction<any>) => Generator<any>
> = {
  ...blockingActionSagasCE,

  // default branch
  [gitArtifactActions.updateDefaultBranchInit.type]: updateDefaultBranchSaga,
};

export const nonBlockingActionSagas: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (action: PayloadAction<any>) => Generator<any>
> = {
  ...nonBlockingActionSagasCE,

  // cd
  [gitArtifactActions.toggleCDInit.type]: toggleCDSaga,
  [gitArtifactActions.generateCDApiKeyInit.type]: generateCDApiKeySaga,
};
