import Api from "api/Api";
import type { ApiResponse } from "api/types";
import type { AxiosPromise } from "axios";

export type GenerateCDApiKeyResponseData = string;

export type GenerateCDApiKeyResponse =
  ApiResponse<GenerateCDApiKeyResponseData>;

export default async function generateCDApiKeyRequest(
  baseApplicationId: string,
): AxiosPromise<GenerateCDApiKeyResponse> {
  return Api.post(`/v1/api-key/git/${baseApplicationId}`);
}
