import { GIT_BASE_URL } from "git/requests/constants";
import { selectGitApiContractsEnabled } from "git/store/selectors/gitFeatureFlagSelectors";
import type { GitArtifactDef } from "git/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";

interface UseCDEndpointProps {
  artifactDef: GitArtifactDef | null;
  branchName: string;
}

export default function useCDEndpoint({
  artifactDef,
  branchName,
}: UseCDEndpointProps) {
  const isGitApiContractsEnabled: boolean = useSelector(
    selectGitApiContractsEnabled,
  );

  const origin = window.location.origin;

  const cdEndpoint = useMemo(() => {
    if (!artifactDef) return null;

    if (isGitApiContractsEnabled) {
      return `${origin}/api${GIT_BASE_URL}/${artifactDef?.artifactType}/${artifactDef?.baseArtifactId}?branchName=${branchName}`;
    } else {
      return `${origin}/api/v1/git/deploy/app/${artifactDef?.baseArtifactId}?branchName=${branchName}`;
    }
  }, [artifactDef, branchName, isGitApiContractsEnabled, origin]);

  return cdEndpoint;
}
