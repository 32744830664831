import React from "react";
import GitModalsCE from "git/ce/components/GitModals";
import CDReconfigureModal from "../CDReconfigureModal";
import CDDisableModal from "../CDDisableModal";
import useGitFeatureFlags from "git/hooks/useGitFeatureFlags";

function GitModals() {
  const { license_git_continuous_delivery_enabled } = useGitFeatureFlags();

  return (
    <>
      <GitModalsCE />
      {license_git_continuous_delivery_enabled && (
        <>
          <CDReconfigureModal />
          <CDDisableModal />
        </>
      )}
    </>
  );
}

export default GitModals;
