export const BASE_WORKFLOW_EDITOR_URL = "/workflow";
export const WORKFLOW_EDITOR_URL = `${BASE_WORKFLOW_EDITOR_URL}/:workflowId`;

export const WORKFLOW_QUERY_EDITOR_PATH = `/queries/:baseQueryId`;
export const WORKFLOW_API_EDITOR_PATH = `/api/:baseApiId`;

export const SAAS_BASE_PATH = `/saas`;
export const SAAS_EDITOR_PATH = `${SAAS_BASE_PATH}/:pluginPackageName`;
export const SAAS_EDITOR_API_ID_PATH = `${SAAS_EDITOR_PATH}/api/:baseApiId`;

export const INTEGRATION_EDITOR_PATH = "/datasources/:selectedTab";
export const SAAS_EDITOR_DATASOURCE_ID_PATH =
  "/saas/:pluginPackageName/datasources/:datasourceId";
export const DATA_SOURCES_EDITOR_ID_PATH = `/datasource/:datasourceId`;

export const JS_COLLECTION_EDITOR_PATH = `/jsObjects`;
export const WORKFLOWS_JS_COLLECTION_EDITOR_PATH = `/jsObjects/:baseCollectionId`;

export const WORKFLOW_NO_QUERIES_SUFFIX = "/no-queries";
export const WORKFLOW_NO_QUERIES_TO_DISPLAY = `${WORKFLOW_EDITOR_URL}${WORKFLOW_NO_QUERIES_SUFFIX}`;

export const WORKFLOW_ADD_TAB_PATH = "/add";
export const WORKFLOW_ADD_QUERY_PATHS = [
  `${WORKFLOW_EDITOR_URL}${WORKFLOWS_JS_COLLECTION_EDITOR_PATH}${WORKFLOW_ADD_TAB_PATH}`,
  `${WORKFLOW_EDITOR_URL}${WORKFLOW_QUERY_EDITOR_PATH}${WORKFLOW_ADD_TAB_PATH}`,
  `${WORKFLOW_EDITOR_URL}${SAAS_EDITOR_API_ID_PATH}${WORKFLOW_ADD_TAB_PATH}`,
  `${WORKFLOW_EDITOR_URL}${WORKFLOW_API_EDITOR_PATH}${WORKFLOW_ADD_TAB_PATH}`,
  `${WORKFLOW_NO_QUERIES_TO_DISPLAY}${WORKFLOW_ADD_TAB_PATH}`,
];
