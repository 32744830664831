import { createArtifactAction } from "git/store/helpers/createArtifactAction";

interface ToggleDisableCDModalPayload {
  open: boolean;
}

export const toggleCDDisableModalAction =
  createArtifactAction<ToggleDisableCDModalPayload>((state, action) => {
    state.ui.cdDisableModalOpen = action.payload.open;

    return state;
  });

export const toggleCDReconfigureModalAction =
  createArtifactAction<ToggleDisableCDModalPayload>((state, action) => {
    state.ui.cdReconfigureModalOpen = action.payload.open;

    return state;
  });
