import {
  WorkflowActivityExecutionStatus,
  WorkflowExecutionStatus,
} from "ee/constants/WorkflowConstants";

export const HistoryStateFilterStates = {
  ALL_RUNS: "ALL_RUNS",
  FAILED_RUNS: "FAILED_RUNS",
};

const successStatuses = [
  WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_COMPLETED,
  WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_CONTINUED_AS_NEW,
];

const inProgressStatuses = [
  WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_RUNNING,
];

const inProgressActivityStatuses = [
  WorkflowActivityExecutionStatus.SCHEDULED,
  WorkflowActivityExecutionStatus.STARTED,
];

const failedActivityStatuses = [
  WorkflowActivityExecutionStatus.FAILED,
  WorkflowActivityExecutionStatus.TIMED_OUT,
];

const ERROR_ICON_PROPS = {
  name: "close-circle-line",
  color: "var(--ads-v2-color-fg-on-error)",
};

const SUCCESS_ICON_PROPS = {
  name: "check-line",
  color: "var(--ads-v2-color-fg-success)",
};

const IN_PROGRESS_ICON_PROPS = {
  name: "loader-line",
  color: "var(--ads-v2-color-border-emphasis-plus)",
};

const CONSOLE_ICON_PROPS = {
  name: "server-line",
  color: "var(--ads-v2-color-border-emphasis-plus)",
};

export const getWorkflowRunStatusIconProps = (
  status: WorkflowExecutionStatus,
) => {
  if (successStatuses.includes(status)) {
    return SUCCESS_ICON_PROPS;
  } else if (inProgressStatuses.includes(status)) {
    return IN_PROGRESS_ICON_PROPS;
  }

  return ERROR_ICON_PROPS;
};

export const getWorkflowActivityStatusIconProps = (
  status: WorkflowActivityExecutionStatus,
) => {
  if (failedActivityStatuses.includes(status)) {
    return ERROR_ICON_PROPS;
  } else if (inProgressActivityStatuses.includes(status)) {
    return IN_PROGRESS_ICON_PROPS;
  } else if (status === WorkflowActivityExecutionStatus.COMPLETED) {
    return SUCCESS_ICON_PROPS;
  }

  return CONSOLE_ICON_PROPS;
};
