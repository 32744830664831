import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GitArtifactType, GitContextProvider } from "git";
import { getWorkspaceIdForImport } from "ee/selectors/applicationSelectors";
import {
  hasGitPkgConnectPermission,
  hasGitPkgManageDefaultBranchPermission,
} from "ee/utils/permissionHelpers";
import {
  fetchAllApplicationsOfWorkspace,
  setWorkspaceIdForImport,
} from "ee/actions/applicationActions";
import { getCurrentAppWorkspace } from "ee/selectors/selectedWorkspaceSelectors";
import {
  getCurrentPackage,
  getPackagesList,
} from "ee/selectors/packageSelectors";
import type { Package } from "ee/constants/PackageConstants";
import packageStatusTransformer from "../packageStatusTransformer";
import { selectGitPackageEnabled } from "ee/selectors/moduleFeatureSelectors";

interface FlaggedGitPackageContextProviderProps {
  children: JSX.Element;
}

function FlaggedGitPackageContextProvider({
  children,
}: FlaggedGitPackageContextProviderProps) {
  const dispatch = useDispatch();

  const artifactType = GitArtifactType.Package;
  const artifact = useSelector(getCurrentPackage);
  const artifacts = useSelector(getPackagesList) as Package[];
  const workspace = useSelector(getCurrentAppWorkspace);
  const importWorkspaceId = useSelector(getWorkspaceIdForImport);
  const isConnectPermitted = hasGitPkgConnectPermission(
    artifact?.userPermissions ?? [],
  );

  const setImportWorkspaceId = useCallback(() => {
    dispatch(
      setWorkspaceIdForImport({ editorId: "", workspaceId: workspace.id }),
    );
  }, [dispatch, workspace.id]);

  const fetchApplications = useCallback(() => {
    dispatch(fetchAllApplicationsOfWorkspace());
  }, [dispatch]);

  const isManageDefaultBranchPermitted = useMemo(() => {
    return hasGitPkgManageDefaultBranchPermission(
      artifact?.userPermissions ?? [],
    );
  }, [artifact]);

  return (
    <GitContextProvider
      artifact={artifact ?? null}
      artifactType={artifactType}
      artifacts={artifacts ?? null}
      baseArtifactId={artifact?.baseId ?? ""}
      fetchArtifacts={fetchApplications}
      importWorkspaceId={importWorkspaceId}
      isConnectPermitted={isConnectPermitted}
      isManageAutocommitPermitted={false}
      isManageDefaultBranchPermitted={isManageDefaultBranchPermitted}
      isManageProtectedBranchesPermitted={false}
      setImportWorkspaceId={setImportWorkspaceId}
      statusTransformer={packageStatusTransformer}
      workspace={workspace ?? null}
    >
      {children}
    </GitContextProvider>
  );
}

interface GitPackageContextProviderProps {
  children: JSX.Element;
}

export default function GitPackageContextProvider({
  children,
}: GitPackageContextProviderProps) {
  const isGitPackageEnabled = useSelector(selectGitPackageEnabled);

  if (!isGitPackageEnabled) {
    return children;
  }

  return (
    <FlaggedGitPackageContextProvider>
      {children}
    </FlaggedGitPackageContextProvider>
  );
}
