export * from "ce/sagas/ApiCallerSagas";
import { call, put, select } from "redux-saga/effects";
import ModuleApi from "ee/api/ModuleApi";
import {
  updateActionAPICall as CE_updateActionAPICall,
  updateJSCollectionAPICall as CE_updateJSCollectionAPICall,
} from "ce/sagas/ApiCallerSagas";
import type { ApiResponse } from "api/ApiResponses";
import type { Action } from "entities/Action";
import { ENTITY_TYPE } from "ee/entities/DataTree/types";
import { getIsActionConverting } from "ee/selectors/entitiesSelector";
import type { JSCollection } from "entities/JSCollection";
import { waitForUpdatingModuleReferences } from "./moduleSagas";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import {
  endModuleActionTracking,
  startModuleActionTracking,
} from "ee/actions/moduleActions";

export function* updateActionAPICall(action: Action) {
  try {
    const isActionConverting: boolean = yield select(
      getIsActionConverting,
      action.id,
    );

    if (isActionConverting) {
      return;
    }

    if (action.pageId || action.workflowId) {
      const response: ApiResponse<Action> = yield call(
        CE_updateActionAPICall,
        action,
      );

      return response;
    } else {
      yield put(startModuleActionTracking(ReduxActionTypes.UPDATE_ACTION_INIT));
      const isUpdatingModuleReferencesSuccess: boolean = yield call(
        waitForUpdatingModuleReferences,
      );

      if (!isUpdatingModuleReferencesSuccess) {
        return;
      }

      const response: ApiResponse<Action> = yield ModuleApi.updateAction({
        ...action,
        type: ENTITY_TYPE.ACTION,
      } as unknown as Action);

      return response;
    }
  } catch (e) {
    throw e;
  } finally {
    yield put(endModuleActionTracking(ReduxActionTypes.UPDATE_ACTION_INIT));
  }
}

export function* updateJSCollectionAPICall(jsCollection: JSCollection) {
  try {
    if (jsCollection.pageId || jsCollection.workflowId) {
      const response: ApiResponse<JSCollection> = yield call(
        CE_updateJSCollectionAPICall,
        jsCollection,
      );

      return response;
    } else {
      yield put(
        startModuleActionTracking(
          ReduxActionTypes.UPDATE_JS_FUNCTION_PROPERTY_INIT,
        ),
      );

      const isUpdatingModuleReferencesSuccess: boolean = yield call(
        waitForUpdatingModuleReferences,
      );

      if (!isUpdatingModuleReferencesSuccess) {
        return;
      }

      const response: ApiResponse<JSCollection> =
        yield ModuleApi.updateJSCollection({
          ...jsCollection,
          type: ENTITY_TYPE.JSACTION,
        } as unknown as JSCollection);

      return response;
    }
  } catch (e) {
    throw e;
  } finally {
    yield put(
      endModuleActionTracking(
        ReduxActionTypes.UPDATE_JS_FUNCTION_PROPERTY_INIT,
      ),
    );
  }
}
