import React from "react";
import CDReconfigureModalView from "./CDReconfigureModalView";
import useSettings from "git/hooks/useSettings";
import useContinuousDelivery from "git/ee/hooks/useContinuousDelivery";

function CDReconfigureModal() {
  const {
    generateCDApiKey,
    isCDReconfigureModalOpen,
    toggleCDReconfigureModal,
  } = useContinuousDelivery();
  const { toggleSettingsModal } = useSettings();

  return (
    <CDReconfigureModalView
      generateCDApiKey={generateCDApiKey}
      isCDReconfigureModalOpen={isCDReconfigureModalOpen}
      toggleCDReconfigureModal={toggleCDReconfigureModal}
      toggleSettingsModal={toggleSettingsModal}
    />
  );
}

export default CDReconfigureModal;
