import { format, parseISO } from "date-fns";
import type {
  FileType,
  Integration,
  IntegrationName,
  LocalFilesIntegration,
} from "@appsmith/carbon-connect";
import type { DocumentStatus, DocumentType, RagDocument } from "./types";

export const getBadgeKind = (value: DocumentStatus) => {
  switch (value) {
    case "READY":
      return "success";
    case "SYNCING":
    case "UNKNOWN":
      return "warning";
    case "SYNC_ERROR":
      return "error";
  }
};

export const getIntegrationIcon = (value: DocumentType | IntegrationName) => {
  switch (value) {
    case "TSV":
      return "tsv-file";
    case "CSV":
      return "csv-file";
    case "RTF":
      return "rtf-file";
    case "DOC":
    case "DOCX":
      return "doc-file";
    case "PPT":
    case "PPTX":
      return "ppt-file";
    case "XLSM":
    case "XLSX":
      return "xls-file";
    case "JSON":
      return "json-file";
    case "PDF":
      return "pdf-file";
    case "TEXT":
    case "TXT":
      return "txt-file";
    case "MD":
      return "md-file";
    case "NOTION":
      return "notion";
    case "WEB_SCRAPER":
      return "global-line";
    case "ZENDESK":
      return "zendesk";
    case "GOOGLE_DRIVE":
      return "google-drive";
    case "SALESFORCE":
      return "salesforce";
    case "INTERCOM":
      return "intercom";
    case "SHAREPOINT":
      return "sharepoint";
    case "FRESHDESK":
      return "freshdesk";
    case "CONFLUENCE":
      return "confluence";
    case "DROPBOX":
      return "dropbox";
    case "BOX":
      return "box";
    case "ONEDRIVE":
      return "onedrive";
    default:
      return "file-line";
  }
};

export const getIntegrationTitle = (value?: IntegrationName | string) => {
  switch (value) {
    case "LOCAL_FILES":
      return "Local files";
    case "NOTION":
      return "Notion";
    case "WEB_SCRAPER":
      return "Web scraper";
    case "ZENDESK":
      return "Zendesk";
    case "GOOGLE_DRIVE":
      return "Google drive";
    case "SALESFORCE":
      return "Salesforce";
    case "INTERCOM":
      return "Intercom";
    case "FRESHDESK":
      return "Freshdesk";
    case "CONFLUENCE":
      return "Confluence";
    case "DROPBOX":
      return "Dropbox";
    case "BOX":
      return "Box";
    case "TXT":
      return "TXT";
    case "PDF":
      return "PDF";
    case "MD":
      return "MD";
    case "RTF":
      return "RTF";
    case "DOCX":
      return "DOCX";
    default:
      return value;
  }
};

export const mapDocumentsToTableData = (documents?: RagDocument[]) => {
  if (!documents) return [];

  return documents.map((result) => {
    return {
      key: result.ragId,
      col1: result.syncStatus,
      col2: result.integrationType,
      col3: {
        name: result.name,
        url: result.isWebSource ? result.sourceUrl : result.documentUrl,
      },
      col4: result.integrationType,
      col5: result.size,
      col6: result.chunkOverlap,
      col7: result.chunkSize,
      col8: result.updatedAt,
      // col9: result.ragId,
      col10: {
        documentId: result.ragId,
        integrationType: result.integrationType,
      },
    };
  });
};

export const isoStringToLocalDate = (isoString: string) => {
  return format(parseISO(isoString), "dd.MM.yy HH:mm:ss");
};

export const updateIntegrationProperty = (
  integrations: Integration[],
  id: string,
  property: string,
  value: number,
): Integration[] => {
  return integrations.map((integration) => {
    if (integration.id === id) {
      return {
        ...integration,
        [property]: value,
      };
    }

    return integration;
  });
};

export const updateFileIntegrationProperty = (
  integrations: Integration[],
  extension: string,
  property: string,
  value: number | boolean,
): Integration[] => {
  return integrations.map((integration) => {
    if (integration.id === "LOCAL_FILES") {
      const updatedFileTypes = (
        integration as LocalFilesIntegration
      ).allowedFileTypes?.map((fileType) => {
        if (fileType.extension === extension) {
          return {
            ...fileType,
            [property]: value,
          };
        }

        return fileType;
      });

      return {
        ...integration,
        allowedFileTypes: updatedFileTypes,
      };
    }

    return integration;
  });
};

/**
 * Filters integrations based on the document types present in a given list of documents.
 *
 * The function performs the following steps:
 * 1. If either integrations or documents is undefined, null, or empty, it returns an empty array.
 * 2. Extracts unique document types from the documents array.
 * 3. Iterates over each integration and determines whether it should be included in the result based on:
 *    - For the "LOCAL_FILES" integration, it checks if any of the allowedFileTypes match the document types.
 *    - For other integrations, it checks if the integration's ID matches one of the uniqueDocumentTypes.
 * 4. Returns a filtered array of integration objects that are relevant to the document types.
 *
 * @param integrations - An optional array of integration objects.
 * @param documents - An optional array of document objects.
 * @returns A filtered array of integration objects that are relevant to the document types.
 */
export const filterIntegrationsByDocumentTypes = (
  integrations?: Integration[],
  documents?: RagDocument[],
): Integration[] => {
  if (
    !integrations ||
    !documents ||
    integrations.length === 0 ||
    documents.length === 0
  ) {
    return [];
  }

  const uniqueDocumentTypes = getUniqueDocumentTypes(documents);

  return integrations.flatMap((integration): Integration[] => {
    const { chunkSize, id, overlapSize } = integration;

    if (id === "LOCAL_FILES") {
      const relevantFileTypes = selectFileTypesForDocumentTypes(
        uniqueDocumentTypes,
        (integration as LocalFilesIntegration).allowedFileTypes,
      );

      return relevantFileTypes.length > 0
        ? [{ id, allowedFileTypes: relevantFileTypes }]
        : [];
    }

    if (uniqueDocumentTypes.has(id as DocumentType)) {
      return [
        {
          id,
          chunkSize: chunkSize,
          overlapSize: overlapSize,
        },
      ];
    }

    return [];
  });
};

/**
 * Filters file types based on the document types.
 *
 * This function iterates over the documentTypes set and finds matching file types
 * from the datasourceFileTypes array. If no match is found in datasourceFileTypes,
 * it falls back to the integrationFileTypes array. File types that do not match any document type
 * are excluded from the result.
 *
 * @param documentTypes - A set of document types used to filter.
 * @param allowedFileTypes - An optional array of allowedFileTypes.
 * @returns An array of file types that match the document types.
 */
const selectFileTypesForDocumentTypes = (
  documentTypes: Set<DocumentType>,
  allowedFileTypes?: FileType[],
): FileType[] => {
  return Array.from(documentTypes)
    .map((docType) =>
      allowedFileTypes?.find((fileType) => fileType.extension === docType),
    )
    .filter((fileType): fileType is FileType => fileType !== undefined);
};

/**
 * Extracts unique document integrationTypes from an array of documents.
 *
 * This function maps the integrationType property of each document in the documents array.
 * The resulting document integrationTypes are stored in a `Set` to ensure uniqueness.
 *
 * @param documents - An array of documents.
 * @returns A set of sorted unique document integrationTypes.
 */
const getUniqueDocumentTypes = (
  documents: RagDocument[],
): Set<DocumentType> => {
  const allTypes = documents.map((doc) => {
    // There is a mismatch of types in carbon connect and carbon sdk.
    // So we return TXT instead of TEXT and WEB_SCRAPER instead of WEB_SCRAPE.

    if (doc.integrationType === "TEXT") return "TXT";

    if (doc.integrationType === "WEB_SCRAPE") return "WEB_SCRAPER";

    return doc.integrationType;
  });

  // Return only unique types and sort them to have always same order.
  return new Set(allTypes.sort());
};
