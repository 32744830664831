import { useGitContext } from "git/components/GitContextProvider";
import {
  selectCDEnabled,
  selectDisableCDModalOpen,
  selectGenerateCDApiKeyState,
  selectReconfigureCDModalOpen,
  selectToggleCDState,
} from "git/ee/store/selectors/gitArtifactSelectors";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import useArtifactSelector from "git/hooks/useArtifactSelector";

export default function useContinuousDelivery() {
  const { artifactDef } = useGitContext();

  const dispatch = useDispatch();

  const isCDEnabled = useArtifactSelector(selectCDEnabled);

  const toggleCDState = useArtifactSelector(selectToggleCDState);

  const toggleCD = useCallback(() => {
    if (artifactDef) {
      dispatch(gitArtifactActions.toggleCDInit({ artifactDef }));
    }
  }, [artifactDef, dispatch]);

  const generateCDApiKeyState = useArtifactSelector(
    selectGenerateCDApiKeyState,
  );

  const generateCDApiKey = useCallback(() => {
    if (artifactDef) {
      dispatch(gitArtifactActions.generateCDApiKeyInit({ artifactDef }));
    }
  }, [artifactDef, dispatch]);

  const resetGenerateCDApiKey = useCallback(() => {
    if (artifactDef) {
      dispatch(gitArtifactActions.resetGenerateCDApiKey({ artifactDef }));
    }
  }, [artifactDef, dispatch]);

  const isCDReconfigureModalOpen = useArtifactSelector(
    selectReconfigureCDModalOpen,
  );

  const toggleCDReconfigureModal = useCallback(
    (open: boolean) => {
      if (artifactDef) {
        dispatch(
          gitArtifactActions.toggleCDReconfigureModal({ artifactDef, open }),
        );
      }
    },
    [artifactDef, dispatch],
  );

  const isCDDisableModalOpen = useArtifactSelector(selectDisableCDModalOpen);

  const toggleCDDisableModal = useCallback(
    (open: boolean) => {
      if (artifactDef) {
        dispatch(
          gitArtifactActions.toggleCDDisableModal({ artifactDef, open }),
        );
      }
    },
    [artifactDef, dispatch],
  );

  return {
    isCDEnabled: isCDEnabled ?? false,
    isToggleCDLoading: toggleCDState?.loading ?? false,
    toggleCDLoadingError: toggleCDState?.error ?? null,
    toggleCD,
    cdApiKey: generateCDApiKeyState?.value ?? null,
    isGenerateCDApiKeyLoading: generateCDApiKeyState?.loading ?? false,
    generateCDApiKeyError: generateCDApiKeyState?.error ?? null,
    generateCDApiKey,
    resetGenerateCDApiKey,
    isCDReconfigureModalOpen: isCDReconfigureModalOpen ?? false,
    toggleCDReconfigureModal,
    isCDDisableModalOpen: isCDDisableModalOpen ?? false,
    toggleCDDisableModal,
  };
}
