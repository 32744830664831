import { toast } from "@appsmith/ads";
import { createMessage, IMPORT_PACKAGE_SUCCESS } from "ee/constants/messages";
// import { showReconnectDatasourceModal } from "ee/actions/applicationActions";
import type { GitImportSuccessPayload } from "git/store/actions/gitImportActions";
import type { GitArtifactPayloadAction } from "git/store/types";
// import { put } from "redux-saga/effects";
import history from "utils/history";
import type { GitPackageArtifact } from "git/types";
import { BASE_PACKAGE_EDITOR_PATH } from "ee/constants/routes/packageRoutes";

export default function* packageImportFromGitSaga(
  action: GitArtifactPayloadAction<GitImportSuccessPayload>,
) {
  const { responseData } = action.payload;
  //   const { isPartialImport, unConfiguredDatasourceList } = responseData;

  const pkg = (responseData.package as GitPackageArtifact) ?? null;

  if (!pkg) return;

  // there is configuration-missing datasources
  //   if (isPartialImport) {
  //     yield put(
  //       showReconnectDatasourceModal({
  //         application: pkg as GitPackageArtifact,
  //         unConfiguredDatasourceList: unConfiguredDatasourceList ?? [],
  //         workspaceId: pkg?.workspaceId ?? "",
  //       }),
  //     );
  //   } else {

  let url = `${BASE_PACKAGE_EDITOR_PATH}/${pkg.baseId}`;
  const branchName = pkg?.gitArtifactMetadata?.branchName;

  if (branchName) {
    url += `?branch=${branchName}`;
  }

  history.push(url);

  toast.show(createMessage(IMPORT_PACKAGE_SUCCESS), {
    kind: "success",
  });
}
