export * from "ce/IDE/Interfaces/IDETypes";
import { IDE_TYPE as CE_IDE_TYPE } from "ce/IDE/Interfaces/IDETypes";

export const IDE_TYPE = {
  ...CE_IDE_TYPE,
  Package: "Package",
  Workflow: "Workflow",
} as const;

export type IDEType = keyof typeof IDE_TYPE;
