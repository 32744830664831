import { gitArtifactCaseReducers as gitArtifactCaseReducersCE } from "git/ce/store/actions";
import {
  updateDefaultBranchErrorAction,
  updateDefaultBranchInitAction,
  updateDefaultBranchSuccessAction,
} from "./updateDefaultBranchActions";
import {
  generateCDApiKeyErrorAction,
  generateCDApiKeyInitAction,
  generateCDApiKeySuccessAction,
  resetGenerateCDApiKeyAction,
} from "./generateCDApiKeyActions";
import {
  toggleCDErrorAction,
  toggleCDInitAction,
  toggleCDSuccessAction,
} from "./toggleCDActions";
import {
  toggleCDDisableModalAction,
  toggleCDReconfigureModalAction,
} from "./uiActions";

export { gitConfigCaseReducers } from "git/ce/store/actions";

export const gitArtifactCaseReducers = {
  ...gitArtifactCaseReducersCE,

  // default branch
  updateDefaultBranchInit: updateDefaultBranchInitAction,
  updateDefaultBranchSuccess: updateDefaultBranchSuccessAction,
  updateDefaultBranchError: updateDefaultBranchErrorAction,

  // cd
  generateCDApiKeyInit: generateCDApiKeyInitAction,
  generateCDApiKeySuccess: generateCDApiKeySuccessAction,
  generateCDApiKeyError: generateCDApiKeyErrorAction,
  resetGenerateCDApiKey: resetGenerateCDApiKeyAction,
  toggleCDInit: toggleCDInitAction,
  toggleCDSuccess: toggleCDSuccessAction,
  toggleCDError: toggleCDErrorAction,
  toggleCDReconfigureModal: toggleCDReconfigureModalAction,
  toggleCDDisableModal: toggleCDDisableModalAction,
};
