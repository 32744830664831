import React from "react";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useCurrentWorkflowState } from "./useCurrentWorkflowState";
import { EditorState } from "IDE/enums";
import {
  APP_SIDEBAR_WIDTH,
  APP_LIBRARIES_PANE_WIDTH,
  DEFAULT_EXPLORER_PANE_WIDTH,
} from "constants/AppConstants";
import {
  type Area,
  Areas,
  SETTINGS_PANE_WIDTH,
  SIDEBAR_WIDTH,
} from "../constants";
import type { AnimatedGridUnit } from "components/AnimatedGridLayout";

interface ReturnValue {
  areas: Area[][];
  rows: AnimatedGridUnit[];
  columns: AnimatedGridUnit[];
}

function useGridLayoutTemplate(): ReturnValue {
  const areas = React.useMemo(function initialiseAreas() {
    return [[Areas.Sidebar, Areas.Explorer, Areas.CodeEditor]];
  }, []);
  const [columns, setColumns] = React.useState<AnimatedGridUnit[]>([]);
  const [rows] = React.useState<AnimatedGridUnit[]>(["1fr"]);

  const [windowWidth] = useWindowDimensions();
  const editorStateLeftPaneWidth = DEFAULT_EXPLORER_PANE_WIDTH + 1;
  const workflowState = useCurrentWorkflowState();

  React.useEffect(
    function updateIDEColumns() {
      switch (workflowState) {
        case EditorState.DATA:
          setColumns([
            SIDEBAR_WIDTH,
            "300px",
            `${windowWidth - 300 - 50}px`,
            "0px",
          ]);

          break;
        case EditorState.SETTINGS:
          setColumns([
            SIDEBAR_WIDTH,
            `${SETTINGS_PANE_WIDTH}px`,
            `${windowWidth - APP_SIDEBAR_WIDTH - SETTINGS_PANE_WIDTH}px`,
            "0px",
          ]);

          break;
        case EditorState.LIBRARIES:
          setColumns([
            SIDEBAR_WIDTH,
            `${APP_LIBRARIES_PANE_WIDTH}px`,
            `${windowWidth - APP_SIDEBAR_WIDTH - APP_LIBRARIES_PANE_WIDTH}px`,
            "0px",
          ]);

          break;
        case EditorState.EDITOR:
          setColumns([
            SIDEBAR_WIDTH,
            `${editorStateLeftPaneWidth}px`,
            `${windowWidth - APP_SIDEBAR_WIDTH - editorStateLeftPaneWidth + 1}px`,
            `0px`,
          ]);
      }
    },
    [workflowState, editorStateLeftPaneWidth, windowWidth],
  );

  return { areas, columns, rows };
}

export { useGridLayoutTemplate };
