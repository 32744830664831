export * from "ce/reducers/entityReducers/canvasWidgetsReducer";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "ce/reducers/entityReducers/canvasWidgetsReducer";
import {
  handlers as CE_handlers,
  getUpdatedWidgetLists,
  initialState,
} from "ce/reducers/entityReducers/canvasWidgetsReducer";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { CreateUIModuleSuccessPayload } from "ee/actions/moduleActions";
import { createImmerReducer } from "utils/ReducerUtils";
import {
  getCanvasBottomRow,
  getCanvasWidgetHeightsToUpdate,
} from "utils/WidgetSizeUtils";
import type { UpdateCanvasPayload } from "actions/pageActions";
import { diff } from "deep-diff";

const resetCanvasWidgets = (
  draftState: CanvasWidgetsReduxState,
  action: ReduxAction<CreateUIModuleSuccessPayload<FlattenedWidgetProps>>,
) => {
  const { widgets } = action.payload;
  const newWidgets: CanvasWidgetsReduxState = {};

  for (const [widgetId, widgetProps] of Object.entries(widgets)) {
    if (widgetProps.type === "CANVAS_WIDGET") {
      const bottomRow = getCanvasBottomRow(widgetId, widgets);

      newWidgets[widgetId] = {
        ...widgetProps,
        bottomRow,
      };
    } else {
      newWidgets[widgetId] = widgetProps;
    }
  }

  return newWidgets;
};

const handlers = {
  ...CE_handlers,
  [ReduxActionTypes.CREATE_UI_MODULE_SUCCESS]: resetCanvasWidgets,
  [ReduxActionTypes.INIT_MODULE_CANVAS_LAYOUT]: resetCanvasWidgets,

  [ReduxActionTypes.UPDATE_MODULE_LAYOUT_INIT]: (
    state: CanvasWidgetsReduxState,
    action: ReduxAction<UpdateCanvasPayload>,
  ) => {
    let listOfUpdatedWidgets;

    // if payload has knowledge of which widgets were changed, use that
    if (action.payload.updatedWidgetIds) {
      listOfUpdatedWidgets = action.payload.updatedWidgetIds;
    } // else diff out the widgets that need to be updated
    else {
      const updatedLayoutDiffs = diff(state, action.payload.widgets);

      if (!updatedLayoutDiffs) return state;

      listOfUpdatedWidgets = getUpdatedWidgetLists(updatedLayoutDiffs);
    }

    //update only the widgets that need to be updated.
    for (const widgetId of listOfUpdatedWidgets) {
      const updatedWidget = action.payload.widgets[widgetId];

      if (updatedWidget) {
        state[widgetId] = updatedWidget;
      } else {
        delete state[widgetId];
      }
    }

    const canvasWidgetHeightsToUpdate: Record<string, number> =
      getCanvasWidgetHeightsToUpdate(listOfUpdatedWidgets, state);

    for (const widgetId in canvasWidgetHeightsToUpdate) {
      state[widgetId] = {
        ...state[widgetId],
        bottomRow: canvasWidgetHeightsToUpdate[widgetId],
      };
    }
  },
};

const canvasWidgetsReducer = createImmerReducer(initialState, handlers);

export default canvasWidgetsReducer;
