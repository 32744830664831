import CreateNewModuleMenu from "../../../components/PackageExplorer/Modules/CreateNewModuleMenu";
import ModuleEntities from "../../../components/PackageExplorer/Modules/ModuleEntities";
import { getAllModules } from "ee/selectors/modulesSelector";
import { getCurrentPackage } from "ee/selectors/packageSelectors";
import { hasCreateModulePermission } from "ee/utils/permissionHelpers";
import EntityAddButton from "pages/Editor/Explorer/Entity/AddButton";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { ListItemContainer, ListWithHeader } from "@appsmith/ads";
import { objectKeys } from "@appsmith/utils";
import { useBoolean } from "usehooks-ts";
import type { ModulesSectionProps } from "ee/pages/PackageIDE/types";

export function ModulesSection({ onItemSelected }: ModulesSectionProps) {
  const {
    setFalse: closeMenu,
    setTrue: openMenu,
    value: isMenuOpen,
  } = useBoolean(false);

  const modules = useSelector(getAllModules);
  const currentPackage = useSelector(getCurrentPackage);
  const userPackagePermissions = currentPackage?.userPermissions ?? [];

  const canCreateModules = hasCreateModulePermission(userPackagePermissions);

  const onCloseMenu = useCallback(() => {
    closeMenu();
    onItemSelected();
  }, [closeMenu, onItemSelected]);

  const createModuleControl = useMemo(() => {
    if (!canCreateModules) return null;

    return (
      <CreateNewModuleMenu
        canCreate={canCreateModules}
        closeMenu={onCloseMenu}
        isOpen={isMenuOpen}
        triggerElement={
          <EntityAddButton className="create-module-btn" onClick={openMenu} />
        }
      />
    );
  }, [canCreateModules, isMenuOpen, onCloseMenu, openMenu]);

  return (
    <ListWithHeader
      headerClassName="modules"
      headerControls={createModuleControl}
      headerText={`All Modules (${objectKeys(modules).length})`}
      maxHeight={"300px"}
    >
      <ListItemContainer>
        <ModuleEntities
          canCreateModules={canCreateModules}
          onItemSelected={onItemSelected}
          openCreateNewMenu={openMenu}
          packageId={currentPackage?.id || ""}
        />
      </ListItemContainer>
    </ListWithHeader>
  );
}
