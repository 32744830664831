import {
  Button,
  Menu,
  MenuContent,
  MenuItem,
  MenuTrigger,
} from "@appsmith/ads";
import { openGenerateApprovalPageModal } from "ee/pages/GenerateWorkflowApprovalPage/store/generateWorkflowApprovalPageActions";
import {
  createMessage,
  WORKFLOW_POST_DEPLOY_ACTION_GENERATE_APPROVAL_PAGE,
} from "ee/constants/messages";
import React from "react";
import { useDispatch } from "react-redux";

export const WorkflowPostDeployActions = () => {
  const dispatch = useDispatch();

  function onGeneratePageClick() {
    dispatch(openGenerateApprovalPageModal());
  }

  return (
    <Menu>
      <MenuTrigger>
        <Button
          className="t--deploy-popup-option-trigger"
          isIconButton
          kind="tertiary"
          size="md"
          startIcon={"down-arrow"}
        />
      </MenuTrigger>
      <MenuContent>
        <MenuItem
          className="t--generate-approval-page-action"
          onClick={onGeneratePageClick}
        >
          {createMessage(WORKFLOW_POST_DEPLOY_ACTION_GENERATE_APPROVAL_PAGE)}
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};
