import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, OptGroup, Option, Select, toast } from "@appsmith/ads";
import type { ApiResponseError } from "api/types";
import type {
  GroupedDocuments,
  RagDocument,
  RagDocumentsSelectorProps,
} from "./types";
import {
  fetchAllAndSelectedDocuments,
  updateSelectedDocuments,
} from "./RagApiRequests";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSelectedRagDocumentsInit,
  updateSelectedRagDocumentsSuccess,
} from "ee/actions/ragDocumentsActions";

export const RagDocumentsSelector = (props: RagDocumentsSelectorProps) => {
  const dispatch = useDispatch();
  const { actionId, datasourceId, workspaceId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<GroupedDocuments>(
    {} as GroupedDocuments,
  );
  const [documents, setDocuments] = useState<GroupedDocuments>(
    {} as GroupedDocuments,
  );
  const ragDocuments = useSelector(
    // Not using AppState as it is causing circular deps
    (state: { ai: { ragDocuments: Record<string, RagDocument[]> } }) =>
      datasourceId ? state.ai.ragDocuments?.[datasourceId] : undefined,
  );

  const onFetchDocuments = useCallback(
    async (datasourceId: string, workspaceId: string, actionId: string) => {
      setIsLoading(true);

      try {
        const response = await fetchAllAndSelectedDocuments(
          datasourceId,
          workspaceId,
          actionId,
        );

        setDocuments(response.documents);
        setSelectedDocuments(response.selectedDocuments);
      } catch (error) {
        toast.show((error as ApiResponseError).message, { kind: "error" });
      }

      setIsLoading(false);
    },
    [],
  );

  const ragDocumentsIDs = ragDocuments?.map((doc) => doc.ragId).join(",");

  useEffect(
    function handleFetchDocuments() {
      if (datasourceId && workspaceId && actionId) {
        onFetchDocuments(datasourceId, workspaceId, actionId);
      }
    },
    [ragDocumentsIDs, datasourceId, workspaceId, actionId, onFetchDocuments],
  );

  function onSelectedValuesChange(selectedValues: string[]) {
    if (datasourceId && workspaceId && actionId) {
      const allDocuments = Object.entries(documents).flatMap(([type, docs]) =>
        docs.map((doc) => ({
          id: doc.id,
          integrationType: type,
        })),
      );
      const selectedDocs = Object.entries(selectedDocuments).flatMap(
        ([type, docs]) =>
          docs.map((doc) => ({
            id: doc.id,
            integrationType: type,
          })),
      );
      const addedDocuments = allDocuments.filter(
        (doc) =>
          selectedValues.includes(doc.id) &&
          !selectedDocs.some((selectedDoc) => selectedDoc.id === doc.id),
      );
      const removedDocuments = selectedDocs.filter(
        (doc) => !selectedValues.includes(doc.id),
      );

      const newSelectedDocuments: GroupedDocuments = {} as GroupedDocuments;

      selectedValues.forEach((id) => {
        for (const [type, docs] of Object.entries(documents) as [
          keyof GroupedDocuments,
          GroupedDocuments[keyof GroupedDocuments],
        ][]) {
          const doc = docs.find((doc) => doc.id === id);

          if (doc) {
            if (!newSelectedDocuments[type]) {
              newSelectedDocuments[type] = [];
            }

            newSelectedDocuments[type].push(doc);
          }
        }
      });

      setSelectedDocuments(newSelectedDocuments);

      dispatch(updateSelectedRagDocumentsInit());

      updateSelectedDocuments(
        datasourceId,
        workspaceId,
        actionId,
        addedDocuments,
        removedDocuments,
      )
        .catch((error) => {
          toast.show((error as ApiResponseError).message, { kind: "error" });
        })
        .finally(() => {
          dispatch(updateSelectedRagDocumentsSuccess());
        });
    }
  }

  const selectedValues = Object.entries(selectedDocuments).flatMap(([, docs]) =>
    docs.map((doc) => doc.id),
  );

  return (
    <Select
      isLoading={isLoading}
      isMultiSelect
      onDeselect={(value) => {
        onSelectedValuesChange(selectedValues.filter((opt) => opt !== value));
      }}
      onSelect={(value) => {
        onSelectedValuesChange([...selectedValues, value]);
      }}
      optionFilterProp="label"
      optionLabelProp="label"
      placeholder="Select documents"
      showSearch
      value={selectedValues}
      virtual
    >
      {Object.entries(documents).map(([type, docs]) => {
        return (
          <OptGroup key={type} label={type}>
            {docs.map((doc) => (
              <Option key={doc.id} label={doc.name} value={doc.id}>
                <Checkbox isSelected={selectedValues.includes(doc.id)}>
                  {doc.name}
                </Checkbox>
              </Option>
            ))}
          </OptGroup>
        );
      })}
    </Select>
  );
};
