export * from "ce/PluginActionEditor/components/PostActionRun/types";
import { PostRunActionNames as CE_PostRunActionNames } from "ce/PluginActionEditor/components/PostActionRun/types";

export const PostRunActionNames = {
  ...CE_PostRunActionNames,
  WORKFLOW_APPROVAL_RESOLUTION: "Resolution",
} as const;

export type PostRunActionNamesInterface =
  (typeof PostRunActionNames)[keyof typeof PostRunActionNames];

export interface ResolveHITLQueryPayload {
  actionId: string;
  baseActionId: string;
  requestId: string;
  workflowId: string;
  resolution: string;
  resolutionMetadata: Record<string, string>;
  viewMode?: boolean;
}
