import React from "react";
import CDDisableModalView from "./CDDisableModalView";
import useContinuousDelivery from "git/ee/hooks/useContinuousDelivery";

function CDDisableModal() {
  const {
    isCDDisableModalOpen,
    isToggleCDLoading,
    toggleCD,
    toggleCDDisableModal,
  } = useContinuousDelivery();

  return (
    <CDDisableModalView
      isCDdisableModalOpen={isCDDisableModalOpen}
      isToggleCDLoading={isToggleCDLoading}
      toggleCD={toggleCD}
      toggleCDDisableModal={toggleCDDisableModal}
    />
  );
}

export default CDDisableModal;
