export * from "ce/entities/IDE/utils";
import {
  EDITOR_PATHS as CE_EDITOR_PATHS,
  type EditableTabPermissions,
  type SaveEntityName,
} from "ce/entities/IDE/utils";
import { MODULE_EDITOR_PATH } from "ee/constants/routes/packageRoutes";
import { WORKFLOW_EDITOR_URL } from "ee/constants/routes/workflowRoutes";
import { saveActionName } from "actions/pluginActionActions";
import { saveModuleInstanceName } from "ee/actions/moduleInstanceActions";
import { EditorEntityTab } from "IDE/Interfaces/EditorTypes";
import { saveJSObjectName } from "actions/jsActionActions";
import { hasManageModuleInstancePermission } from "ee/utils/permissionHelpers";
import { getHasManageActionPermission } from "ee/utils/BusinessFeatures/permissionPageHelpers";

export const EDITOR_PATHS = [
  ...CE_EDITOR_PATHS,
  MODULE_EDITOR_PATH,
  WORKFLOW_EDITOR_URL,
];

export const saveEntityName = ({ entity, params, segment }: SaveEntityName) => {
  let saveNameAction = saveActionName(params);

  if (entity?.isModuleInstance) {
    saveNameAction = saveModuleInstanceName(params);
  } else if (EditorEntityTab.JS === segment) {
    saveNameAction = saveJSObjectName(params);
  }

  return saveNameAction;
};

export const getEditableTabPermissions = ({
  entity,
  isFeatureEnabled,
}: EditableTabPermissions) => {
  return entity?.isModuleInstance
    ? hasManageModuleInstancePermission(entity?.userPermissions || [])
    : getHasManageActionPermission(
        isFeatureEnabled,
        entity?.userPermissions || [],
      );
};
