import React from "react";
import { Flex, Icon, Text } from "@appsmith/ads";
import { getIntegrationIcon, getIntegrationTitle } from "./RagUtils";
import type { DocumentType } from "./types";
import type { IntegrationName } from "@appsmith/carbon-connect";

interface RagIntegrationTitleProps {
  title: string;
  variant?: "title" | "subtitle";
}

export const RagIntegrationTitle = (props: RagIntegrationTitleProps) => {
  const { title, variant = "title" } = props;

  const isTitle = variant === "title";

  return (
    <Flex
      gap={isTitle ? "spaces-3" : "spaces-2"}
      marginBottom={isTitle ? "spaces-5" : "spaces-4"}
    >
      <Icon
        name={getIntegrationIcon(title as DocumentType | IntegrationName)}
        size={isTitle ? "lg" : "md"}
      />
      <Text kind={isTitle ? "heading-m" : "heading-s"}>
        {getIntegrationTitle(title)}
      </Text>
    </Flex>
  );
};
