export * from "ce/utils/licenseHelpers";
import React from "react";
import { useSelector } from "react-redux";
import {
  isAdminUser,
  isFreePlan,
  isTrialLicense,
  shouldShowLicenseBanner,
} from "ee/selectors/organizationSelectors";
import { isAirgapped } from "ee/utils/airgapHelpers";
import store from "store";
import { customerPortalPlansUrl } from "ee/utils/billingUtils";
import { createMessage, UPGRADE } from "ee/constants/messages";
import { useRouteMatch } from "react-router";
import PageBannerMessage from "ee/pages/common/PageWrapperBanner";
import { Button } from "@appsmith/ads";

export const getLicenseKey = () => {
  const state = store.getState();
  const licenseKey =
    state?.organization?.organizationConfiguration?.license?.key;

  return licenseKey || "";
};

export const pricingPageUrlSource = "BE";

export const ShowUpgradeMenuItem = () => {
  const isTrial = useSelector(isTrialLicense);
  const isAdmin = useSelector(isAdminUser);
  const isFree = useSelector(isFreePlan);
  const isAirgappedInstance = isAirgapped();

  return (isTrial || isFree) && isAdmin && !isAirgappedInstance ? (
    <Button
      className="business-plan-menu-option mr-2"
      data-testid="t--upgrade-to-business"
      kind="secondary"
      onClick={() => window.open(customerPortalPlansUrl, "_blank")}
    >
      {createMessage(UPGRADE)}
    </Button>
  ) : null;
};

export const Banner = () => {
  const showBanner = useSelector(shouldShowLicenseBanner);
  const isHomePage = useRouteMatch("/applications")?.isExact;
  const isLicensePage = useRouteMatch("/license")?.isExact;

  return showBanner && (isHomePage || isLicensePage) ? (
    <PageBannerMessage />
  ) : null;
};
