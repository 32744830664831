export const RagConstants = {
  // Number of chunks which RAG should return.
  DEFAULT_SEARCH_NUMBER_OF_CHUNKS: 20,
  // Whether to use high accuracy or not.
  DEFAULT_SEARCH_HIGH_ACCURACY: true,
  DEFAULT_SEARCH_INCLUDE_RAW_FILE: true,
  DEFAULT_DOCUMENT_INCLUDE_RAW_FILE: true,
  DEFAULT_DOCUMENT_INCLUDE_PARSED_RAW_FILE: true,
  DEFAULT_DOCUMENT_PAGINATION_LIMIT: 100,
  MAX_DOCUMENT_PAGINATION_LIMIT: 250,
  CITATION_PATTERN: "<span data-citation-id=':citationId'></span>", // This is a regex pattern
  CITATION_REGEX: /<span data-citation-id=['"]([^'"]+)['"]><\/span>/g,
  COLON: ":",
  COMMA: ",",
  INSTANCE_ID: "instanceId",
  TENANT_ID: "tenantId",
  WORKSPACE_ID: "workspaceId",
  DATASOURCE_ID: "datasourceId",
  WIDGET_ID: "widgetId",
  USERNAME: "username",
  CONTENT: "content",
  CITATION_ID: "citationId",
  SIMILARITY_SCORE: "similarityScore",
  RAG_QUERY_SYSTEM_ROLE_HEADER: `
────────────────────────────────────────
SYSTEM ROLE
────────────────────────────────────────
  `,
  RAG_QUERY_SYSTEM_INSTRUCTIONS_HEADER: `
────────────────────────────────────────
SYSTEM INSTRUCTIONS
────────────────────────────────────────
  `,
  RAG_QUERY_USER_INSTRUCTIONS_HEADER: `
────────────────────────────────────────
USER INSTRUCTIONS
────────────────────────────────────────
  `,
  RAG_QUERY_USER_QUERY_HEADER: `
────────────────────────────────────────
USER QUERY
────────────────────────────────────────
  `,
  RAG_QUERY_CONVERSATION_HISTORY_HEADER: `
────────────────────────────────────────
CONVERSATION HISTORY
────────────────────────────────────────
  `,
  RAG_QUERY_MAIN_TASK_HEADER: `
────────────────────────────────────────
MAIN TASK
────────────────────────────────────────
  `,
  RAG_QUERY_OUTPUT_FORMAT_HEADER: `
────────────────────────────────────────
OUTPUT FORMAT
────────────────────────────────────────
  `,
  RAG_QUERY_SYSTEM_ROLE_STRING: `
You are an expert assistant specializing in refining user queries to maximize retrieval accuracy from a Qdrant vector database. You will produce an optimized, rephrased query ready for embedding-based vector search.
  `,
  RAG_QUERY_SYSTEM_INSTRUCTIONS_STRING: `
1. Examine the user’s original query, the most recent conversation context, and any additional user instructions.  
2. Rephrase the query to be clearer, more specific, and better aligned with how the stored content is phrased.  
3. Preserve the user’s original intent and detail.  
4. Where beneficial, add clarifications to improve retrieval precision while adhering to the user’s intent.  
5. Output only the final, refined query—no extra explanations or commentary.
  `,
  RAG_QUERY_MAIN_TASK_STRING: `
• Identify any relevant context or instructions from the conversation.  
• Pinpoint the user’s core intent.  
• Formulate a refined query that will be most effective for embedding-based retrieval in Qdrant.  
• Ensure the query matches likely stored language and does not stray from the user’s purpose.
  `,
  RAG_QUERY_OUTPUT_FORMAT_STRING: `
• A single, final, optimized query.
  `,
};
