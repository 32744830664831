export * from "ce/AppRouter";
import { Routes as CE_Routes } from "ce/AppRouter";
import React, { Suspense, useEffect } from "react";
import history from "utils/history";
import AppHeader from "ee/pages/common/AppHeader";
import { Router, Switch } from "react-router-dom";
import ErrorPage from "pages/common/ErrorPage";
import PageLoadingBar from "pages/common/PageLoadingBar";
import ErrorPageHeader from "pages/common/ErrorPageHeader";
import { useDispatch, useSelector } from "react-redux";

import useBrandingTheme from "utils/hooks/useBrandingTheme";
import RouteChangeListener from "RouteChangeListener";
import { isValidLicense } from "ee/selectors/organizationSelectors";
import LicenseCheckPage from "./pages/LicenseSetup/LicenseCheckPage";
import { LICENSE_CHECK_PATH, MIGRATIONS_URL } from "constants/routes";
import { requiresLicenseCheck } from "./requiresLicenseCheck";
import ProductAlertBanner from "components/editorComponents/ProductAlertBanner";
import Walkthrough from "components/featureWalkthrough";
import PackageIDELoader from "./pages/PackageIDE";
import {
  MODULE_EDITOR_PATH,
  PACKAGE_EDITOR_PATH,
} from "ee/constants/routes/packageRoutes";
import { getShowQueryModule } from "ee/selectors/moduleFeatureSelectors";
import { Migrations } from "./pages/Billing/Migrations";
import { WORKFLOW_EDITOR_URL } from "./constants/routes/workflowRoutes";
import WorkflowIDELoader from "./pages/WorkflowIDE";
import { getShowWorkflowFeature } from "./selectors/workflowSelectors";
import { getIsConsolidatedPageLoading } from "selectors/ui";
import { initCurrentPage } from "actions/initActions";
import { getSafeCrash, getSafeCrashCode } from "selectors/errorSelectors";
import type { ERROR_CODES } from "ee/constants/ApiConstants";
import { SentryRoute } from "components/SentryRoute";

const loadingIndicator = <PageLoadingBar />;

const EE_Routes = requiresLicenseCheck(() => {
  return <CE_Routes />;
});

function AppRouter() {
  const isLicenseValid = useSelector(isValidLicense);
  const safeCrash: boolean = useSelector(getSafeCrash);
  const safeCrashCode: ERROR_CODES | undefined = useSelector(getSafeCrashCode);

  const showQueryModule = useSelector(getShowQueryModule);
  const showWorkflows = useSelector(getShowWorkflowFeature);
  const isConsolidatedPageLoading = useSelector(getIsConsolidatedPageLoading);
  const dispatch = useDispatch();

  useEffect(function initializeCurrentPage() {
    dispatch(initCurrentPage());
  }, []);

  useBrandingTheme();
  const isLoading = isConsolidatedPageLoading;

  useEffect(
    function hideTopLoaderOnceOrganizationLoaded() {
      if (!isLoading) {
        const loader = document.getElementById("loader") as HTMLDivElement;

        if (loader) {
          loader.style.width = "100vw";

          setTimeout(() => {
            loader.style.opacity = "0";
          });
        }
      }
    },
    [isLoading],
  );

  if (isLoading) return null;

  return (
    <Router history={history}>
      <Suspense fallback={loadingIndicator}>
        <RouteChangeListener />
        {safeCrash && safeCrashCode ? (
          <>
            <ErrorPageHeader />
            <ErrorPage code={safeCrashCode} />
          </>
        ) : (
          <>
            <Walkthrough>
              {isLicenseValid && <AppHeader />}
              <Switch>
                {!isLicenseValid && (
                  <SentryRoute
                    component={LicenseCheckPage}
                    path={LICENSE_CHECK_PATH}
                  />
                )}

                {showWorkflows && (
                  <SentryRoute
                    component={WorkflowIDELoader}
                    path={WORKFLOW_EDITOR_URL}
                  />
                )}

                {showQueryModule && (
                  <SentryRoute
                    component={PackageIDELoader}
                    path={MODULE_EDITOR_PATH}
                  />
                )}

                {showQueryModule && (
                  <SentryRoute
                    component={PackageIDELoader}
                    path={PACKAGE_EDITOR_PATH}
                  />
                )}
                <SentryRoute component={Migrations} path={MIGRATIONS_URL} />
                <EE_Routes />
              </Switch>
            </Walkthrough>
            <ProductAlertBanner />
          </>
        )}
      </Suspense>
    </Router>
  );
}

export default AppRouter;
