import { create } from "mutative";

import { createReducer } from "utils/ReducerUtils";

import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";

type ActionId = string;
type TabId = string;
type WorkflowId = string;

export interface WorkflowIDEState {
  /** Tab ids grouped by workspace, tab ids correspond to action ids. */
  workflowTabIds: Record<WorkflowId, Set<TabId>>;
  /** Statuses for name updates. */
  actionNameSavingStatus: Record<TabId, boolean>;
}

export interface WorkflowTabAction {
  tabId: TabId;
  workflowId: WorkflowId;
}

interface WorkflowNameSaveAction {
  payload: {
    id: ActionId;
  };
}

// New reducer for managing IDE tabs using same WorkFlowIdeState interface
const initialState: WorkflowIDEState = {
  actionNameSavingStatus: {},
  workflowTabIds: {},
};

export const handlers = {
  [ReduxActionTypes.ADD_WORKFLOW_TAB]: (
    state: WorkflowIDEState,
    action: ReduxAction<WorkflowTabAction>,
  ) =>
    create(state, (draft) => {
      const { tabId, workflowId } = action.payload;

      if (!draft.workflowTabIds[workflowId]) {
        draft.workflowTabIds[workflowId] = new Set();
      }

      draft.workflowTabIds[workflowId].add(tabId);
    }),
  [ReduxActionTypes.REMOVE_WORKFLOW_TAB]: (
    state: WorkflowIDEState,
    action: ReduxAction<WorkflowTabAction>,
  ) =>
    create(state, (draft) => {
      const { tabId, workflowId } = action.payload;

      draft.workflowTabIds[workflowId].delete(tabId);
    }),
  [ReduxActionTypes.SAVE_ACTION_NAME_FOR_WORKFLOWS_INIT]: (
    state: WorkflowIDEState,
    action: WorkflowNameSaveAction,
  ) =>
    create(state, (draft) => {
      draft.actionNameSavingStatus[action.payload.id] = true;
    }),
  [ReduxActionTypes.SAVE_ACTION_NAME_FOR_WORKFLOWS_SUCCESS]: (
    state: WorkflowIDEState,
    action: WorkflowNameSaveAction,
  ) =>
    create(state, (draft) => {
      draft.actionNameSavingStatus[action.payload.id] = false;
    }),
  [ReduxActionErrorTypes.SAVE_ACTION_NAME_FOR_WORKFLOWS_ERROR]: (
    state: WorkflowIDEState,
    action: WorkflowNameSaveAction,
  ) =>
    create(state, (draft) => {
      draft.actionNameSavingStatus[action.payload.id] = false;
    }),
};

export const workflowIDEReducer = createReducer(initialState, handlers);
