import type { GitArtifactPayloadAction } from "git/store/types";
import type { GenerateCDApiKeyResponse } from "../requests/generateCDApiKeyRequest";
import { call, put } from "redux-saga/effects";
import generateCDApiKeyRequest from "../requests/generateCDApiKeyRequest";
import { validateResponse } from "sagas/ErrorSagas";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import handleApiErrors from "git/sagas/helpers/handleApiErrors";

export function* generateCDApiKeySaga(action: GitArtifactPayloadAction) {
  const { artifactDef } = action.payload;

  let response: GenerateCDApiKeyResponse | undefined;

  try {
    response = yield call(generateCDApiKeyRequest, artifactDef.baseArtifactId);
    const isValidResponse: boolean = yield validateResponse(response);

    if (response && isValidResponse) {
      yield put(
        gitArtifactActions.generateCDApiKeySuccess({
          artifactDef,
          responseData: response.data,
        }),
      );
    }
  } catch (e) {
    const error = handleApiErrors(e as Error, response);

    if (error) {
      yield put(
        gitArtifactActions.generateCDApiKeyError({ artifactDef, error }),
      );
    }
  }
}
