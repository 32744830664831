import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  jsCollectionAddURL,
  jsCollectionListURL,
  queryAddURL,
  queryListURL,
} from "ee/RouteBuilder";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { getQueryModuleEntityItemUrl } from "ee/pages/PackageIDE/layouts/routers/utils/getQueryModuleEntityItemUrl";
import type { ModuleEntityItem } from "ee/pages/PackageIDE/types";
import {
  getNextEntityAfterRemove,
  RedirectAction,
} from "IDE/utils/getNextEntityAfterRemove";
import log from "loglevel";
import { FocusEntity, type FocusEntityInfo } from "navigation/FocusEntity";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import history from "utils/history";
import {
  getAllJSTabs,
  getAllQueryTabs,
  selectJSModuleSegmentEditorTabs,
  selectQueryModuleSegmentEditorTabs,
} from "ee/selectors/packageIDESelectors";
import { getCurrentBaseModuleId } from "ee/selectors/modulesSelector";
import { getJSModuleEntityItemUrl } from "ee/pages/PackageIDE/layouts/routers/utils/getJSModuleEntityItemUrl";
import {
  closeJSModuleActionTabSuccess,
  closeQueryModuleActionTabSuccess,
  setPkgIDEJSTabs,
  setPkgIDEQueryTabs,
} from "ee/actions/packageIDEActions";
import { getUpdatedTabs } from "IDE/utils/getUpdatedTabs";

export function* updatePackageIDETabsOnRouteChangeSaga(
  entityInfo: FocusEntityInfo,
) {
  const { entity, id, params } = entityInfo;

  if (!params.basePackageId) return;

  if (
    entity === FocusEntity.JS_OBJECT ||
    entity === FocusEntity.JS_MODULE_INSTANCE
  ) {
    const jsTabs: string[] = yield select(getAllJSTabs);
    const newTabs: string[] = yield call(getUpdatedTabs, id, jsTabs);

    yield put(setPkgIDEJSTabs(newTabs, params.basePackageId));
  }

  if (
    entity === FocusEntity.QUERY ||
    entity === FocusEntity.QUERY_MODULE_INSTANCE
  ) {
    const queryTabs: string[] = yield select(getAllQueryTabs);
    const newTabs: string[] = yield call(getUpdatedTabs, id, queryTabs);

    yield put(setPkgIDEQueryTabs(newTabs, params.basePackageId));
  }
}

export function* closeQueryModuleTabSaga(
  actionPayload: ReduxAction<{
    id: string;
    parentId: string;
  }>,
) {
  const { id, parentId } = actionPayload.payload;
  // const currentUrl = window.location.pathname;

  // yield call(FocusRetention.handleRemoveFocusHistory, currentUrl);
  yield call(handleQueryModuleEntityRedirect, id);
  yield put(closeQueryModuleActionTabSuccess({ id, parentId }));
}

export function* closeJSModuleTabSaga(
  actionPayload: ReduxAction<{
    id: string;
    parentId: string;
  }>,
) {
  const { id, parentId } = actionPayload.payload;
  // const currentUrl = window.location.pathname;

  // yield call(FocusRetention.handleRemoveFocusHistory, currentUrl);
  yield call(handleJSModuleEntityRedirect, id);
  yield put(closeJSModuleActionTabSuccess({ id, parentId }));
}

export function* handleJSModuleEntityRedirect(deletedId: string) {
  const baseModuleId: string = yield select(getCurrentBaseModuleId);
  const jsTabs: ModuleEntityItem[] = yield select(
    selectJSModuleSegmentEditorTabs,
  );
  const redirectAction = getNextEntityAfterRemove(deletedId, jsTabs);

  switch (redirectAction.action) {
    case RedirectAction.LIST:
      history.push(jsCollectionListURL({ baseModuleId }));
      break;
    case RedirectAction.ITEM:
      if (!redirectAction.payload) {
        log.error("Redirect item does not have a payload");
        history.push(jsCollectionAddURL({ baseModuleId }));
        break;
      }

      const { payload } = redirectAction;

      history.push(getJSModuleEntityItemUrl(payload));
      break;
  }
}

export function* handleQueryModuleEntityRedirect(deletedId: string) {
  const baseModuleId: string = yield select(getCurrentBaseModuleId);
  const queryTabs: ModuleEntityItem[] = yield select(
    selectQueryModuleSegmentEditorTabs,
  );
  const redirectAction = getNextEntityAfterRemove(deletedId, queryTabs);

  switch (redirectAction.action) {
    case RedirectAction.LIST:
      history.push(queryListURL({ baseModuleId }));
      break;
    case RedirectAction.ITEM:
      if (!redirectAction.payload) {
        history.push(queryAddURL({ baseModuleId }));
        log.error("Redirect item does not have a payload");
        break;
      }

      const { payload } = redirectAction;

      history.push(getQueryModuleEntityItemUrl(payload));
      break;
  }
}

export default function* packageIDESaga() {
  yield all([
    takeLatest(
      ReduxActionTypes.CLOSE_QUERY_MODULE_ACTION_TAB,
      closeQueryModuleTabSaga,
    ),
    takeLatest(
      ReduxActionTypes.CLOSE_JS_MODULE_ACTION_TAB,
      closeJSModuleTabSaga,
    ),
  ]);
}
