import React from "react";
import DefaultBranchView from "git/ce/components/DefaultBranch/DefaultBranchView";
import useDefaultBranch from "git/ee/hooks/useDefaultBranch";
import useGitFeatureFlags from "git/hooks/useGitFeatureFlags";
import useBranches from "git/hooks/useBranches";
import { useGitContext } from "git/components/GitContextProvider";

export default function DefaultBranch() {
  const { artifactDef } = useGitContext();
  const { branches } = useBranches();
  const { updateDefaultBranch } = useDefaultBranch();
  const { license_git_branch_protection_enabled } = useGitFeatureFlags();
  const { artifactType } = artifactDef ?? {};

  return (
    <DefaultBranchView
      artifactType={artifactType ?? null}
      branches={branches}
      isGitProtectedFeatureLicensed={license_git_branch_protection_enabled}
      updateDefaultBranch={updateDefaultBranch}
    />
  );
}
