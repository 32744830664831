import type { FieldValues, RegisterOptions } from "react-hook-form";
import type { Workflow as CE_Workflow } from "ce/constants/WorkflowConstants";

export interface WorkflowScheduleSpecInterface {
  cronExpressions: Array<string>;
  timeZoneName: string;
}

export enum WorkflowScheduleState {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface WorkflowScheduleInterface {
  scheduleSpec: WorkflowScheduleSpecInterface;
  triggerData: Record<string, unknown>;
  name: string;
  state: WorkflowScheduleState;
}

export interface Workflow extends CE_Workflow {
  schedules?: Record<string, WorkflowScheduleInterface>;
}

export type WorkflowMetadata = Pick<
  Workflow,
  "id" | "name" | "workspaceId" | "icon" | "color" | "modifiedAt" | "modifiedBy"
>;

export const DEFAULT_WORKFLOW_COLOR = "#9747FF1A";
export const DEFAULT_WORKFLOW_ICON = "workflows";
export const DEFAULT_WORKFLOW_PREFIX = "Untitled Workflow ";
export const WORKFLOW_SETTINGS_PANE_WIDTH = 250;

export enum WorkflowSettingsTabs {
  Trigger = "trigger",
  General = "general",
}

export interface WorkflowSchedulerFormDataType {
  timezone: string;
  workflowScheduleMinute: string;
  workflowScheduleHour: string;
  workflowScheduleDOM: string;
  workflowScheduleMonth: string;
  workflowScheduleDOW: string;
}

export enum WokrflowSchedulerFormFields {
  timezone = "timezone",
  workflowScheduleMinute = "workflowScheduleMinute",
  workflowScheduleHour = "workflowScheduleHour",
  workflowScheduleDOM = "workflowScheduleDOM",
  workflowScheduleMonth = "workflowScheduleMonth",
  workflowScheduleDOW = "workflowScheduleDOW",
}

export interface WorkflowSchedulerFormConfigType {
  label: () => string;
  tooltip: () => string;
  placeholder: () => string;
  validationRules: Omit<
    RegisterOptions<FieldValues, WokrflowSchedulerFormFields>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

export enum RUN_HISTORY_TAB_KEYS {
  RUN_HISTORY = "RUN_HISTORY",
}

export enum WorkflowExecutionStatus {
  WORKFLOW_EXECUTION_STATUS_UNSPECIFIED = 0,
  WORKFLOW_EXECUTION_STATUS_RUNNING = 1,
  WORKFLOW_EXECUTION_STATUS_COMPLETED = 2,
  WORKFLOW_EXECUTION_STATUS_FAILED = 3,
  WORKFLOW_EXECUTION_STATUS_CANCELED = 4,
  WORKFLOW_EXECUTION_STATUS_TERMINATED = 5,
  WORKFLOW_EXECUTION_STATUS_CONTINUED_AS_NEW = 6,
  WORKFLOW_EXECUTION_STATUS_TIMED_OUT = 7,
}

export enum WorkflowActivityExecutionStatus {
  SCHEDULED = 0,
  STARTED = 1,
  COMPLETED = 2,
  FAILED = 3,
  TIMED_OUT = 4,
  CONSOLE = 5,
}
