import React from "react";
import { useGitContext } from "git/components/GitContextProvider";
import useSettings from "git/hooks/useSettings";
import useDefaultBranch from "git/ee/hooks/useDefaultBranch";
import useContinuousDelivery from "git/ee/hooks/useContinuousDelivery";
import CDLicensedView from "./CDLicensedView";
import useBranches from "git/hooks/useBranches";

export default function CDLicensed() {
  const { artifact, artifactDef } = useGitContext();
  const { branches, isFetchBranchesLoading } = useBranches();
  const { toggleSettingsModal } = useSettings();
  const { defaultBranch } = useDefaultBranch();
  const {
    cdApiKey,
    generateCDApiKey,
    isCDDisableModalOpen,
    isCDEnabled,
    isCDReconfigureModalOpen,
    isGenerateCDApiKeyLoading,
    isToggleCDLoading,
    resetGenerateCDApiKey,
    toggleCD,
    toggleCDDisableModal,
    toggleCDReconfigureModal,
  } = useContinuousDelivery();
  const artifactLastDeployedAt = artifact?.lastDeployedAt ?? null;

  return (
    <CDLicensedView
      artifactDef={artifactDef}
      artifactLastDeployedAt={artifactLastDeployedAt}
      branches={branches}
      cdApiKey={cdApiKey}
      defaultBranch={defaultBranch}
      generateCDApiKey={generateCDApiKey}
      isCDDisableModalOpen={isCDDisableModalOpen}
      isCDEnabled={isCDEnabled}
      isCDReconfigureModalOpen={isCDReconfigureModalOpen}
      isFetchBranchesLoading={isFetchBranchesLoading}
      isGenerateCDApiKeyLoading={isGenerateCDApiKeyLoading}
      isToggleCDLoading={isToggleCDLoading}
      resetGenerateCDApiKey={resetGenerateCDApiKey}
      toggleCD={toggleCD}
      toggleCDDisableModal={toggleCDDisableModal}
      toggleCDReconfigureModal={toggleCDReconfigureModal}
      toggleSettingsModal={toggleSettingsModal}
    />
  );
}
