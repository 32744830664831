export * from "ce/reducers/organizationReducer";
import type { OrganizationReduxState } from "ce/reducers/organizationReducer";
import {
  handlers as CE_Handlers,
  initialState as CE_InitialState,
  defaultBrandingConfig,
} from "ce/reducers/organizationReducer";
import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import { createReducer } from "utils/ReducerUtils";
import {
  cachedOrganizationConfigParsed,
  createBrandColorsFromPrimaryColor,
} from "utils/BrandingUtils";
import type { License } from "./organizationReducer.types";

const INITIAL_BRAND_COLOR = "#000";

// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialState: OrganizationReduxState<any> = {
  ...CE_InitialState,
  organizationConfiguration: {
    ...CE_InitialState.organizationConfiguration,
    brandColors: {
      ...createBrandColorsFromPrimaryColor(INITIAL_BRAND_COLOR),
    },
    ...cachedOrganizationConfigParsed,
  },
};

export const handlers = {
  ...CE_Handlers,
  [ReduxActionTypes.FETCH_CURRENT_ORGANIZATION_CONFIG_SUCCESS]: (
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: OrganizationReduxState<any>,
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: ReduxAction<OrganizationReduxState<any>>,
  ) => ({
    ...state,
    userPermissions: action.payload.userPermissions || [],
    organizationConfiguration: {
      ...defaultBrandingConfig,
      ...state.organizationConfiguration,
      ...action.payload.organizationConfiguration,
      brandColors: {
        ...defaultBrandingConfig.brandColors,
        ...action.payload.organizationConfiguration?.brandColors,
      },
      license: {
        ...state.organizationConfiguration?.license,
        ...action.payload.organizationConfiguration?.license,
      },
    },
    isLoading: false,
    instanceId: action.payload.instanceId || state.instanceId || "",
  }),
  [ReduxActionTypes.VALIDATE_LICENSE_KEY]: (
    state: OrganizationReduxState<License>,
    action: ReduxAction<{ key: string; isUserOnboarding: boolean }>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        validatingLicense: true,
        isFree: action.payload?.key === "",
      },
    },
  }),
  [ReduxActionTypes.VALIDATE_LICENSE_KEY_SUCCESS]: (
    state: OrganizationReduxState<License>,
    action: ReduxAction<OrganizationReduxState<License>>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration?.license,
        ...action.payload.organizationConfiguration?.license,
        invalidLicenseKeyError: false,
        validatingLicense: false,
      },
    },
  }),
  [ReduxActionErrorTypes.VALIDATE_LICENSE_KEY_ERROR]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        invalidLicenseKeyError: true,
        validatingLicense: false,
      },
    },
  }),
  [ReduxActionTypes.STOP_LICENSE_STATUS_CHECK]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: initialState.organizationConfiguration.license,
    },
  }),
  [ReduxActionTypes.SHOW_LICENSE_MODAL]: (
    state: OrganizationReduxState<License>,
    action: ReduxAction<boolean>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        showLicenseModal: action.payload,
        invalidLicenseKeyError: !action.payload && false,
      },
    },
  }),
  [ReduxActionTypes.REFRESH_LICENSE_INIT]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        refreshingLicense: true,
      },
    },
  }),
  [ReduxActionTypes.REFRESH_LICENSE_SUCCESS]: (
    state: OrganizationReduxState<License>,
    action: ReduxAction<OrganizationReduxState<License>>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        ...action.payload.organizationConfiguration?.license,
        refreshingLicense: false,
      },
    },
  }),
  [ReduxActionErrorTypes.REFRESH_LICENSE_ERROR]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        refreshingLicense: false,
      },
    },
  }),
  [ReduxActionTypes.SHOW_REMOVE_LICENSE_MODAL]: (
    state: OrganizationReduxState<License>,
    action: ReduxAction<boolean>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        showRemoveLicenseModal: action.payload,
      },
    },
  }),
  [ReduxActionTypes.REMOVE_LICENSE_INIT]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        removingLicense: true,
      },
    },
  }),
  [ReduxActionTypes.REMOVE_LICENSE_SUCCESS]: (
    state: OrganizationReduxState<License>,
    action: ReduxAction<OrganizationReduxState<License>>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        ...action.payload.organizationConfiguration?.license,
        removingLicense: false,
      },
    },
  }),
  [ReduxActionErrorTypes.REMOVE_LICENSE_ERROR]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        removingLicense: false,
      },
    },
  }),
  [ReduxActionTypes.VALIDATE_LICENSE_KEY_DRY_RUN_INIT]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        validatingLicense: true,
      },
    },
  }),
  [ReduxActionTypes.VALIDATE_LICENSE_KEY_DRY_RUN_SUCCESS]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration?.license,
        validatingLicense: false,
      },
    },
  }),
  [ReduxActionErrorTypes.VALIDATE_LICENSE_KEY_DRY_RUN_ERROR]: (
    state: OrganizationReduxState<License>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        validatingLicense: false,
      },
    },
  }),
  [ReduxActionTypes.SHOW_DOWNGRADE_LICENSE_MODAL]: (
    state: OrganizationReduxState<License>,
    action: ReduxAction<boolean>,
  ) => ({
    ...state,
    organizationConfiguration: {
      ...state.organizationConfiguration,
      license: {
        ...state.organizationConfiguration.license,
        showDowngradeLicenseModal: action.payload,
      },
    },
  }),
};

export default createReducer(initialState, handlers);
