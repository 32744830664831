import type { FetchStatusResponseData } from "git/requests/fetchStatusRequest.types";
import {
  createMessage,
  NOT_PUSHED_YET,
  TRY_TO_PULL,
} from "ee/constants/messages";
import type { StatusTreeStruct } from "git/components/StatusChanges/types";

const ICON_LOOKUP = {
  "query module": "query",
  "js module": "js",
  page: "page-line",
  datasource: "database-2-line",
  jsLib: "package",
  settings: "settings-v3",
  theme: "sip-line",
  remote: "git-commit",
  package: "package",
  module: "package",
  moduleInstance: "package",
};

interface TreeNodeDef {
  subject: string;
  verb: string;
  type: keyof typeof ICON_LOOKUP;
  extra?: string;
}

function createTreeNode(nodeDef: TreeNodeDef) {
  let message = `${nodeDef.subject} ${nodeDef.verb}`;

  if (nodeDef.extra) {
    message += ` ${nodeDef.extra}`;
  }

  return { icon: ICON_LOOKUP[nodeDef.type], message };
}

function determineVerbForDefs(defs: TreeNodeDef[]) {
  const isRemoved = defs.some((def) => def.verb === "removed");
  const isAdded = defs.some((def) => def.verb === "added");
  const isModified = defs.some((def) => def.verb === "modified");

  let action = "";

  if (isRemoved && !isAdded && !isModified) {
    action = "removed";
  } else if (isAdded && !isRemoved && !isModified) {
    action = "added";
  } else {
    action = "modified";
  }

  return action;
}

function createTreeNodeGroup(nodeDefs: TreeNodeDef[], subject: string) {
  return {
    icon: ICON_LOOKUP[nodeDefs[0].type],
    message: `${nodeDefs.length} ${subject} ${determineVerbForDefs(nodeDefs)}`,
    children: nodeDefs
      .sort((a, b) =>
        a.subject.localeCompare(b.subject, undefined, { sensitivity: "base" }),
      )
      .map(createTreeNode),
  };
}

function statusModuleTransformer(status: FetchStatusResponseData) {
  const {
    jsObjectsAdded,
    jsObjectsModified,
    jsObjectsRemoved,
    modulesAdded = [],
    modulesModified = [],
    modulesRemoved = [],
    queriesAdded,
    queriesModified,
    queriesRemoved,
  } = status;
  const entityDefLookup: TreeNodeDef[] = [];
  const addToEntityDefLookup = (
    files: string[],
    type: keyof typeof ICON_LOOKUP,
    verb: string,
  ) => {
    files.forEach((file) => {
      let subject = file;

      if (file.includes("/")) {
        [, subject] = file.split("/");
      }

      entityDefLookup.push({ subject, verb, type });
    });
  };

  addToEntityDefLookup(queriesModified, "query module", "modified");
  addToEntityDefLookup(queriesAdded, "query module", "added");
  addToEntityDefLookup(queriesRemoved, "query module", "removed");
  addToEntityDefLookup(jsObjectsModified, "js module", "modified");
  addToEntityDefLookup(jsObjectsAdded, "js module", "added");
  addToEntityDefLookup(jsObjectsRemoved, "js module", "removed");

  addToEntityDefLookup(modulesModified, "module", "modified");
  addToEntityDefLookup(modulesAdded, "module", "added");
  addToEntityDefLookup(modulesRemoved, "module", "removed");

  const queryDefs = entityDefLookup.filter(
    (def) => def.type === "query module",
  );
  const jsObjectDefs = entityDefLookup.filter(
    (def) => def.type === "js module",
  );
  const moduleDefs = entityDefLookup
    .filter((def) => def.type === "module")
    .filter(
      (def) => !queryDefs.some((queryDef) => queryDef.subject === def.subject),
    )
    .filter(
      (def) =>
        !jsObjectDefs.some(
          (jsObjectDef) => jsObjectDef.subject === def.subject,
        ),
    );

  const tree = [] as StatusTreeStruct[];

  if (queryDefs.length > 0) {
    const subject = queryDefs.length === 1 ? "query module" : "query modules";

    tree.push(createTreeNodeGroup(queryDefs, subject));
  }

  if (jsObjectDefs.length > 0) {
    const subject = jsObjectDefs.length === 1 ? "js module" : "js modules";

    tree.push(createTreeNodeGroup(jsObjectDefs, subject));
  }

  if (moduleDefs.length > 0) {
    const subject = moduleDefs.length === 1 ? "module" : "modules";

    tree.push(createTreeNodeGroup(moduleDefs, subject));
  }

  return tree;
}

function statusDatasourceTransformer(status: FetchStatusResponseData) {
  const { datasourcesAdded, datasourcesModified, datasourcesRemoved } = status;
  const defs = [] as TreeNodeDef[];

  datasourcesModified.forEach((datasource) => {
    defs.push({ subject: datasource, verb: "modified", type: "datasource" });
  });

  datasourcesAdded.forEach((datasource) => {
    defs.push({ subject: datasource, verb: "added", type: "datasource" });
  });

  datasourcesRemoved.forEach((datasource) => {
    defs.push({ subject: datasource, verb: "removed", type: "datasource" });
  });

  const tree = [] as StatusTreeStruct[];

  if (defs.length > 0) {
    tree.push(createTreeNodeGroup(defs, "datasource"));
  }

  return tree;
}

function statusJsLibTransformer(status: FetchStatusResponseData) {
  const { jsLibsAdded, jsLibsModified, jsLibsRemoved } = status;
  const defs = [] as TreeNodeDef[];

  jsLibsModified.forEach((jsLib) => {
    defs.push({ subject: jsLib, verb: "modified", type: "jsLib" });
  });

  jsLibsAdded.forEach((jsLib) => {
    defs.push({ subject: jsLib, verb: "added", type: "jsLib" });
  });

  jsLibsRemoved.forEach((jsLib) => {
    defs.push({ subject: jsLib, verb: "removed", type: "jsLib" });
  });

  const tree = [] as StatusTreeStruct[];

  if (defs.length > 0) {
    const subject = defs.length === 1 ? "jsLib" : "jsLibs";

    tree.push(createTreeNodeGroup(defs, subject));
  }

  return tree;
}

function statusRemoteCountTransformer(status: FetchStatusResponseData) {
  const { aheadCount, behindCount } = status;
  const tree = [] as StatusTreeStruct[];

  if (behindCount > 0) {
    tree.push(
      createTreeNode({
        subject: `${behindCount} commit${behindCount > 1 ? "s" : ""}`,
        verb: "behind",
        type: "remote",
        extra: createMessage(TRY_TO_PULL),
      }),
    );
  }

  if (aheadCount > 0) {
    tree.push(
      createTreeNode({
        subject: `${aheadCount} commit${aheadCount > 1 ? "s" : ""}`,
        verb: "ahead",
        type: "remote",
        extra: createMessage(NOT_PUSHED_YET),
      }),
    );
  }

  return tree;
}

function statusSettingsTransformer(status: FetchStatusResponseData) {
  const { modified } = status;
  const tree = [] as StatusTreeStruct[];

  if (modified.includes("package.json")) {
    tree.push(
      createTreeNode({
        subject: "Package settings",
        verb: "modified",
        type: "settings",
      }),
    );
  }

  return tree;
}

function statusModuleInstancesTransformer(status: FetchStatusResponseData) {
  const { modifiedModuleInstances = 0, modifiedSourceModules = 0 } = status;
  const tree = [] as StatusTreeStruct[];

  if (modifiedSourceModules > 0) {
    tree.push(
      createTreeNode({
        subject: `${modifiedSourceModules} source module${modifiedSourceModules > 1 ? "s" : ""}`,
        verb: "modified",
        type: "module",
      }),
    );
  }

  if (modifiedModuleInstances > 0) {
    tree.push(
      createTreeNode({
        subject: `${modifiedModuleInstances} module instance${modifiedModuleInstances > 1 ? "s" : ""}`,
        verb: "modified",
        type: "moduleInstance",
      }),
    );
  }

  return tree;
}

export default function packageStatusTransformer(
  status: FetchStatusResponseData,
) {
  const tree = [
    ...statusRemoteCountTransformer(status),
    ...statusModuleTransformer(status),
    ...statusDatasourceTransformer(status),
    ...statusJsLibTransformer(status),
    ...statusSettingsTransformer(status),
    ...statusModuleInstancesTransformer(status),
  ] as StatusTreeStruct[];

  return tree;
}
