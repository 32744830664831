import React, { useMemo, useState } from "react";
import { Button, Select, Text } from "@appsmith/ads";
import {
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/editorComponents/CodeEditor/EditorConfig";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { EditorModes } from "components/editorComponents/CodeEditor/EditorConfig";
import { CodeEditorBorder } from "components/editorComponents/CodeEditor/EditorConfig";
import LazyCodeEditor from "components/editorComponents/LazyCodeEditor";
import { useDispatch, useSelector } from "react-redux";
import { triggerHITLQueryResolution } from "ee/actions/workflowActions";
import { getCurrentWorkflowId } from "ee/selectors/workflowSelectors";
import { usePluginActionContext } from "PluginActionEditor/PluginActionContext";
import { isString } from "utils/helpers";
import {
  createMessage,
  WORKFLOW_APPROVAL_RESOLUTION_FORM_CTA_SUBMIT,
  WORKFLOW_APPROVAL_RESOLUTION_FORM_METADATA_LABEL,
  WORKFLOW_APPROVAL_RESOLUTION_FORM_METADATA_PLACEHOLDER,
  WORKFLOW_APPROVAL_RESOLUTION_FORM_SELECT_LABEL,
  WORKFLOW_APPROVAL_RESOLUTION_FORM_TITLE,
} from "ee/constants/messages";
import { error } from "loglevel";
import JSON5 from "json5";

interface ActionResponseForApprovalForm {
  body?: {
    allowedResolutions?: string[];
    id?: string;
  };
}

function WorkflowApprovalResolution() {
  const { action, actionResponse } = usePluginActionContext() as {
    action: { id: string; baseId: string };
    actionResponse: ActionResponseForApprovalForm;
  };

  const allowedResolutions = actionResponse?.body?.allowedResolutions || [];
  const requestId = actionResponse?.body?.id || "";
  const workflowId = useSelector(getCurrentWorkflowId) || "";

  return (
    <WorkflowApprovalResolutionForm
      actionId={action.id}
      allowedResolutions={allowedResolutions}
      baseActionId={action.baseId}
      requestId={requestId}
      workflowId={workflowId}
    />
  );
}

interface Props {
  actionId: string;
  baseActionId: string;
  allowedResolutions: Array<string>;
  requestId: string;
  workflowId: string;
}

// We need to extract this form out since it is needed to be used in
// both PluginActionEditor response pane and workflow test run pane.
// Workflow test run pane doesn't have the plugin action context and hence needs to
// be passed separately
export function WorkflowApprovalResolutionForm(props: Props) {
  const { allowedResolutions } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasResolutions = allowedResolutions.length > 0;

  const [selectedApproval, setSelectedApproval] = useState<string>(
    allowedResolutions[0] || "",
  );
  const [metadata, setMetadata] = useState<string>("");
  const dispatch = useDispatch();

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement> | string) {
    let value = "";

    if (typeof event !== "string") {
      value = event.target?.value;
    } else {
      value = event;
    }

    if (isString(value)) {
      setMetadata(value);
    }
  }

  const isMetadataValid = useMemo(() => {
    if (metadata.trim() === "") return true;

    try {
      const parsed = JSON5.parse(metadata);

      // Here we assume that if parsing is successful and the result isn't a primitive string,
      // the metadata is valid.
      return typeof parsed !== "string";
    } catch (error) {
      return false;
    }
  }, [metadata]);

  function prepareMetadataString() {
    // In case the metadata is an empty string, send null
    if (metadata.trim() === "") return null;

    try {
      // Parse using JSON5 to allow lenient input formats (e.g. unquoted keys)
      return JSON5.parse(metadata);
    } catch (err) {
      error("Error converting metadata to JSON string:", err);

      return null;
    }
  }

  function onResolve() {
    if (isLoading || !isMetadataValid) return;

    const resolutionMetadata = prepareMetadataString();

    dispatch(
      triggerHITLQueryResolution({
        ...props,
        resolution: selectedApproval,
        resolutionMetadata,
        viewMode: false,
      }),
    );
    setIsLoading(true);
  }

  function onEditorBlur() {
    dispatch({
      type: ReduxActionTypes.ENTITY_UPDATE_SUCCESS,
    });
  }

  return (
    <div className="p-4 flex flex-col w-[60%]">
      <Text className="mb-2" kind="heading-s">
        {createMessage(WORKFLOW_APPROVAL_RESOLUTION_FORM_TITLE)}
      </Text>
      <Text kind="body-m">
        {createMessage(WORKFLOW_APPROVAL_RESOLUTION_FORM_SELECT_LABEL)}
      </Text>
      <Select
        className="mb-2"
        data-testid="t--workflow-approval-resolution-select"
        onChange={setSelectedApproval}
        options={allowedResolutions.map((resolution: string) => ({
          label: resolution,
          value: resolution,
        }))}
        value={selectedApproval}
      />
      <Text kind="body-m">
        {createMessage(WORKFLOW_APPROVAL_RESOLUTION_FORM_METADATA_LABEL)}
      </Text>
      <LazyCodeEditor
        border={CodeEditorBorder.ALL_SIDE}
        height={"90px"}
        ignoreAutoComplete
        ignoreBinding
        ignoreSlashCommand
        input={{
          value: metadata,
          onChange,
        }}
        mode={EditorModes.JSON}
        onEditorBlur={onEditorBlur}
        placeholder={createMessage(
          WORKFLOW_APPROVAL_RESOLUTION_FORM_METADATA_PLACEHOLDER,
        )}
        showLightningMenu={false}
        size={EditorSize.EXTENDED}
        tabBehaviour={TabBehaviour.INDENT}
        theme={EditorTheme.LIGHT}
      />
      <Button
        className="pb-1 mt-2 w-[60px]"
        data-testid="t--workflow-approval-resolution-resolve-button"
        isDisabled={!hasResolutions || !isMetadataValid}
        isLoading={isLoading}
        onClick={onResolve}
      >
        {createMessage(WORKFLOW_APPROVAL_RESOLUTION_FORM_CTA_SUBMIT)}
      </Button>
    </div>
  );
}

export default WorkflowApprovalResolution;
