import { api } from "api/core";
import PluginsApi from "api/PluginApi";
import { toast } from "@appsmith/ads";
import type {
  DocumentType,
  FetchAccessTokenResponse,
  FetchAllAndSelectedDocumentsResponse,
  FetchDocumentsResponse,
  GroupedDocuments,
  RagDocument,
  ResyncDocumentResponse,
} from "./types";

export const fetchAccessToken = async (
  datasourceId: string,
  workspaceId: string,
): Promise<{ access_token: string }> => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<FetchAccessTokenResponse>(apiUrl, {
    datasourceId,
    workspaceId,
    requestType: "GENERATE_RAG_SERVICE_ACCESS_TOKEN",
    displayType: "DROP_DOWN",
  });

  if (!response.responseMeta.success) {
    if (response.responseMeta.error) {
      toast.show(response.responseMeta.error.message, { kind: "error" });
    }

    return Promise.reject(response.responseMeta.error);
  }

  return { access_token: response.data.trigger.accessToken };
};

export const fetchDocuments = async (
  datasourceId: string,
): Promise<{ count: number | null; documents: RagDocument[] }> => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<FetchDocumentsResponse>(apiUrl, {
    datasourceId,
    requestType: "LIST_DOCUMENTS",
    displayType: "DROP_DOWN",
  });

  if (!response.responseMeta.success) {
    if (response.responseMeta.error) {
      toast.show(response.responseMeta.error.message, { kind: "error" });
    }

    return Promise.reject(response.responseMeta.error);
  }

  return {
    count: response.data.trigger.count || null,
    documents: response.data.trigger.results || [],
  };
};

export const fetchAllAndSelectedDocuments = async (
  datasourceId: string,
  workspaceId: string,
  actionId: string,
): Promise<{
  documents: GroupedDocuments;
  selectedDocuments: GroupedDocuments;
}> => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<FetchAllAndSelectedDocumentsResponse>(
    apiUrl,
    {
      datasourceId,
      workspaceId,
      actionId,
      requestType: "LIST_DOCUMENT_PREVIEWS",
      displayType: "DROP_DOWN",
    },
  );

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }

  return {
    documents: response.data.trigger.documentsMap || {},
    selectedDocuments: response.data.trigger.selectedDocumentsMap || {},
  };
};

export const updateSelectedDocuments = async (
  datasourceId: string,
  workspaceId: string,
  actionId: string,
  resourcesToAddTags: {
    id: string;
    integrationType: string;
  }[],
  resourcesToRemoveTags: {
    id: string;
    integrationType: string;
  }[],
) => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<FetchAllAndSelectedDocumentsResponse>(
    apiUrl,
    {
      datasourceId,
      workspaceId,
      actionId,
      requestType: "UPDATE_TAGS_ASSOCIATION_FOR_DOCUMENTS",
      displayType: "DROP_DOWN",
      parameters: {
        resourcesToAddTags,
        resourcesToRemoveTags,
      },
    },
  );

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }
};

export const resyncDocumentRequest = async (
  datasourceId: string,
  documentId: string,
) => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<ResyncDocumentResponse>(apiUrl, {
    datasourceId,
    requestType: "RESYNC_DOCUMENT",
    displayType: "DROP_DOWN",
    parameters: {
      documentId,
    },
  });

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }

  return response.data.trigger.document;
};

export const deleteDocumentRequest = async (
  datasourceId: string,
  documentId: string,
  integrationType: DocumentType,
) => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<void>(apiUrl, {
    datasourceId,
    requestType: "DELETE_DOCUMENTS",
    displayType: "DROP_DOWN",
    parameters: {
      resources: [{ id: documentId, integrationType }],
    },
  });

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }
};
