import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export interface InitPackageEditorPayload {
  basePackageId: string;
  branchName?: string;
}

export const initPackageEditor = (payload: InitPackageEditorPayload) => ({
  type: ReduxActionTypes.INITIALIZE_PACKAGE_EDITOR,
  payload,
});
