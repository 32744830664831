import type { ParentEntityIDETabs } from "ee/reducers/uiReducers/packageIDEReducer";
import { EditorEntityTab } from "IDE/Interfaces/EditorTypes";
import { get, keyBy } from "lodash";
import { getAllJSModules, getAllQueryModules } from "./modulesSelector";
import type { ModuleEntityItem } from "ee/pages/PackageIDE/types";
import { groupAndSortEntitySegmentList } from "IDE/utils/groupAndSortEntitySegmentList";
import {
  ActionUrlIcon,
  JsFileIconV2,
} from "pages/Editor/Explorer/ExplorerIcons";
import { PluginType } from "entities/Plugin";
import { getAssetUrl } from "ee/utils/airgapHelpers";
import {
  isEmbeddedAIDataSource,
  isEmbeddedRestDatasource,
  type Datasource,
} from "entities/Datasource";
import type { AppState } from "ee/reducers";
import { createSelector } from "reselect";
import { getCurrentBasePackageId } from "./packageSelectors";
import type { Module } from "ee/constants/ModuleConstants";
import {
  getIsSavingForApiName,
  getIsSavingForJSObjectName,
} from "selectors/ui";

export interface IsSavingEntityNameParams {
  id: string;
  segment: EditorEntityTab;
  entity?: ModuleEntityItem;
}

export const getPackageIDETabs = (state: AppState) => state.ui.packageide.tabs;

export const getAllQueryTabs = createSelector(
  getCurrentBasePackageId,
  getPackageIDETabs,
  (basePackageId: string, tabs: ParentEntityIDETabs): string[] =>
    get(tabs, [basePackageId, EditorEntityTab.QUERIES], []),
);

export const getAllJSTabs = createSelector(
  getCurrentBasePackageId,
  getPackageIDETabs,
  (basePackageId: string, tabs: ParentEntityIDETabs): string[] =>
    get(tabs, [basePackageId, EditorEntityTab.JS], []),
);

export const selectJSModuleSegmentEditorTabs = (state: AppState) => {
  const items = getJSModulesSegmentItems(state);
  const tabs = getAllJSTabs(state);

  const keyedItems = keyBy(items, "key");

  return tabs
    .map((tab) => {
      return keyedItems[tab];
    })
    .filter(Boolean);
};

export const selectQueryModuleSegmentEditorTabs = (state: AppState) => {
  const items = getQueryModulesSegmentItems(state);
  const tabs = getAllQueryTabs(state);

  const keyedItems = keyBy(items, "key");

  return tabs.map((tab) => keyedItems[tab]).filter(Boolean);
};

export const selectDSIdToNameMap = createSelector(
  (state: AppState) => state.entities.datasources.list,
  (datasources) => {
    return datasources.reduce(
      (acc, datasource) => {
        acc[datasource.id] = datasource.name;

        return acc;
      },
      {} as Record<string, string>,
    );
  },
);

export const getDS = (
  state: AppState,
  datasourceId: string,
): Datasource | undefined =>
  state.entities.datasources.list.find(
    (datasource) => datasource.id === datasourceId,
  );

export const getQueryModulesSegmentItems = createSelector(
  getAllQueryModules,
  (state: AppState) => state.entities.plugins.list,
  selectDSIdToNameMap,
  (state: AppState) => state,
  (modules, plugins, datasourceIdToNameMap, state) => {
    const pluginGroups = keyBy(plugins, "id");
    const items: ModuleEntityItem[] = modules.map((module: Module) => {
      let group;
      const iconUrl = getAssetUrl(pluginGroups[module.pluginId]?.iconLocation);
      const datasource = getDS(state, module?.datasourceId || "");

      if (module.pluginType === PluginType.API) {
        group =
          datasource && isEmbeddedRestDatasource(datasource)
            ? "APIs"
            : datasourceIdToNameMap[datasource?.id || ""] ?? "APIs";
      } else if (module.pluginType === PluginType.AI) {
        group =
          datasource && isEmbeddedAIDataSource(datasource)
            ? "AI Queries"
            : datasourceIdToNameMap[datasource?.id || ""] ?? "AI Queries";
      } else {
        group = datasource?.name ?? datasourceIdToNameMap[datasource?.id || ""];
      }

      return {
        icon: ActionUrlIcon(iconUrl, "16", "16"),
        title: module.name,
        key: module.actionId,
        moduleId: module.baseId,
        type: module.pluginType,
        group,
        userPermissions: module.userPermissions,
      };
    });

    return items;
  },
);

export const getJSModulesSegmentItems = createSelector(
  getAllJSModules,
  (modules) => {
    const items: ModuleEntityItem[] = modules.map((module) => ({
      icon: JsFileIconV2(),
      title: module.name,
      key: module.actionId,
      moduleId: module.baseId,
      type: PluginType.JS,
      userPermissions: module.userPermissions,
    }));

    return items;
  },
);

export const selectQueryModulesSegmentEditorList = createSelector(
  getQueryModulesSegmentItems,
  (items: ModuleEntityItem[]) => {
    return groupAndSortEntitySegmentList(items);
  },
);

export const selectJSModulesSegmentEditorList = createSelector(
  getJSModulesSegmentItems,
  (items: ModuleEntityItem[]) => {
    return groupAndSortEntitySegmentList(items);
  },
);

export const getIsSavingModuleEntityName = (
  state: AppState,
  { id, segment }: IsSavingEntityNameParams,
) => {
  let isSavingEntityName = getIsSavingForApiName(state, id);

  if (EditorEntityTab.JS === segment) {
    isSavingEntityName = getIsSavingForJSObjectName(state, id);
  }

  return isSavingEntityName;
};
