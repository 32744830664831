import type { ApiResponse } from "api/ApiResponses";
import type { AxiosPromise } from "axios";
import type { PullPackageResponse } from "ee/api/PackageApi";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { get, omit } from "lodash";
import { call, race, take } from "redux-saga/effects";

export interface fetchAndRaceWithPackagePullOptions<TPayload> {
  payload: TPayload;
  path: "moduleInstances" | "consumables" | "entities";
}

export function* fetchAndRaceWithPackagePull<TPayload, TResponse>(
  apiFn: (payload: TPayload) => Promise<AxiosPromise<ApiResponse<TResponse>>>,
  options: fetchAndRaceWithPackagePullOptions<TPayload>,
) {
  const { path, payload } = options;
  const { apiResponse, packagePullResponse } = yield race({
    // Run the API call saga (for sagaA or sagaB)
    apiResponse: call(apiFn, payload),

    // Wait for packagePull to complete. If packagePull never runs, this never resolves first.
    packagePullResponse: take(ReduxActionTypes.PULL_PACKAGE_SUCCESS),
  });

  if (packagePullResponse) {
    const pullResponse: ApiResponse<PullPackageResponse> =
      packagePullResponse.payload;
    // If packagePull completed first, get the latest state and use that for the success action
    const data = get(pullResponse.data, path);
    const response = omit(pullResponse, "payload");

    return {
      ...response,
      data,
    };
  } else {
    // Otherwise, proceed with the normal apiResponse
    return apiResponse;
  }
}
