import { createImmerReducer } from "utils/ReducerUtils";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";

export interface ModuleActiveActionsState {
  activeActions: string[];
}

export const initialState: ModuleActiveActionsState = {
  activeActions: [],
};

/**
 * This reducer tracking a particular action's completion status.
 * It can be imagined like placing a block of code within a transaction.
 * How is this useful?
 * If a particular saga is dependent on completion of n other actions then
 * the particular saga can observe the completion of the dependent actions before
 * proceeding. The individual actions emit it's status by placing a start and end
 * between a block of code inside the saga.
 *
 * Why introduce a separate mechanism to track rather than using INIT, SUCCESS and ERROR
 * actions of a particular operation?
 * While using the existing pattern may work by devising a mechanism to look into these
 * actions to identify the start and end of a particular saga and maintaining a running
 * state in this reducer. The only issue here is saga cancellation and multi dispatch. Usually the ERROR action
 * of an operation is placed within a catch block, when a saga is cancelled (race / takeLatest); the catch block is
 * never triggered thus the operation can seem like in active state whereas it's not. In order
 * to improve action completion state; right now explicit start and end block are instituted.
 *
 * Note for future:
 * This mechanism is devised keeping packages operations in mind but can be expanded to other
 * areas. If this is to be expanded then the logic of doing this can be revised and improved
 * to have a holistic way of solving and maintaining this.
 */

export const handlers = {
  [ReduxActionTypes.MODULE_ACTION_START]: (
    draftState: ModuleActiveActionsState,
    action: ReduxAction<{ type: string }>,
  ) => {
    const { type } = action.payload;

    if (!draftState.activeActions.includes(type)) {
      draftState.activeActions.push(type);
    }
  },

  [ReduxActionTypes.MODULE_ACTION_END]: (
    draftState: ModuleActiveActionsState,
    action: ReduxAction<{ type: string }>,
  ) => {
    const idx = draftState.activeActions.findIndex(
      (item) => item === action.payload.type,
    );

    if (idx !== -1) draftState.activeActions.splice(idx, 1);
  },
};

const moduleActiveActionsReducer = createImmerReducer(initialState, handlers);

export default moduleActiveActionsReducer;
