import type { GenerateCDApiKeyResponseData } from "git/ee/requests/generateCDApiKeyRequest";
import { createArtifactAction } from "git/store/helpers/createArtifactAction";
import type {
  GitAsyncSuccessPayload,
  GitAsyncErrorPayload,
} from "git/store/types";

export const generateCDApiKeyInitAction = createArtifactAction((state) => {
  state.apiResponses.generateCDApiKey.loading = true;
  state.apiResponses.generateCDApiKey.error = null;

  return state;
});

export const generateCDApiKeySuccessAction = createArtifactAction<
  GitAsyncSuccessPayload<GenerateCDApiKeyResponseData>
>((state, action) => {
  state.apiResponses.generateCDApiKey.loading = false;
  state.apiResponses.generateCDApiKey.error = null;
  state.apiResponses.generateCDApiKey.value = action.payload.responseData;

  return state;
});

export const generateCDApiKeyErrorAction =
  createArtifactAction<GitAsyncErrorPayload>((state, action) => {
    const { error } = action.payload;

    state.apiResponses.generateCDApiKey.loading = false;
    state.apiResponses.generateCDApiKey.error = error;

    return state;
  });

export const resetGenerateCDApiKeyAction = createArtifactAction((state) => {
  state.apiResponses.generateCDApiKey.loading = false;
  state.apiResponses.generateCDApiKey.error = null;
  state.apiResponses.generateCDApiKey.value = null;

  return state;
});
