export * from "ce/components/gitComponents/ReconfigureCDKeyModal";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from "@appsmith/ads";
import React, { useCallback } from "react";
import {
  GIT_CD_RECONFIGURE_KEY_MODAL_CTA,
  GIT_CD_RECONFIGURE_KEY_MODAL_DESC,
  GIT_CD_RECONFIGURE_KEY_MODAL_TITLE,
  createMessage,
} from "ee/constants/messages";
import noop from "lodash/noop";
import { GitSettingsTab } from "git/constants/enums";

export interface CDReconfigureModalProps {
  generateCDApiKey: () => void;
  isCDReconfigureModalOpen: boolean;
  toggleCDReconfigureModal: (open: boolean) => void;
  toggleSettingsModal: (
    open: boolean,
    tab?: keyof typeof GitSettingsTab,
  ) => void;
}

function CDReconfigureModalView({
  generateCDApiKey = noop,
  isCDReconfigureModalOpen = false,
  toggleCDReconfigureModal = noop,
  toggleSettingsModal = noop,
}: CDReconfigureModalProps) {
  const handleModalOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        toggleCDReconfigureModal(false);
        toggleCDReconfigureModal(false);
        toggleSettingsModal(false);
      }
    },
    [toggleCDReconfigureModal, toggleSettingsModal],
  );

  const handleClickOnReconfigure = useCallback(() => {
    toggleCDReconfigureModal(false);
    generateCDApiKey();
    toggleSettingsModal(true, GitSettingsTab.ContinuousDelivery);
  }, [generateCDApiKey, toggleCDReconfigureModal, toggleSettingsModal]);

  return (
    <Modal onOpenChange={handleModalOpenChange} open={isCDReconfigureModalOpen}>
      <ModalContent
        data-testid="t--cd-reconfigure-key-modal"
        style={{ width: "640px" }}
      >
        <ModalHeader style={{ margin: 0 }}>
          {createMessage(GIT_CD_RECONFIGURE_KEY_MODAL_TITLE)}
        </ModalHeader>
        <ModalBody>
          <Text renderAs="p">
            {createMessage(GIT_CD_RECONFIGURE_KEY_MODAL_DESC)}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid="t--cd-reconfigure-key-btn"
            kind="primary"
            onClick={handleClickOnReconfigure}
            size="md"
          >
            {createMessage(GIT_CD_RECONFIGURE_KEY_MODAL_CTA)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CDReconfigureModalView;
