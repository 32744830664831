import Api from "api/Api";
import { GIT_BASE_URL } from "git/requests/constants";
import type { AxiosPromise } from "axios";
import type { ApiResponse } from "api/types";
import type { GitArtifactType } from "git/constants/enums";

export interface UpdateDefaultBranchRequestParams {
  branchName: string;
}

export type UpdateDefaultBranchResponse = ApiResponse<void>;

async function updateDefaultBranchRequestOld(
  baseApplicationId: string,
  params: UpdateDefaultBranchRequestParams,
): AxiosPromise<UpdateDefaultBranchResponse> {
  return Api.patch(
    `${GIT_BASE_URL}/branch/app/${baseApplicationId}/default`,
    {},
    undefined,
    { params },
  );
}

async function updateDefaultBranchRequestNew(
  artifactType: GitArtifactType,
  baseArtifactId: string,
  params: UpdateDefaultBranchRequestParams,
): AxiosPromise<UpdateDefaultBranchResponse> {
  return Api.patch(
    `${GIT_BASE_URL}/${artifactType}/${baseArtifactId}/branch/default`,
    {},
    undefined,
    { params },
  );
}

export default async function updateDefaultBranchRequest(
  artifactType: GitArtifactType,
  baseArtifactId: string,
  params: UpdateDefaultBranchRequestParams,
  isNew: boolean,
): AxiosPromise<UpdateDefaultBranchResponse> {
  if (isNew) {
    return updateDefaultBranchRequestNew(artifactType, baseArtifactId, params);
  } else {
    return updateDefaultBranchRequestOld(baseArtifactId, params);
  }
}
