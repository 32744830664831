import useDefaultBranchCE from "git/ce/hooks/useDefaultBranch";
import { useGitContext } from "git/components/GitContextProvider";
import { useDispatch } from "react-redux";
import { selectUpdateDefaultBranchState } from "../store/selectors/gitArtifactSelectors";
import { useCallback } from "react";
import { gitArtifactActions } from "git/store/gitArtifactSlice";
import useArtifactSelector from "git/hooks/useArtifactSelector";

const useDefaultBranch = () => {
  const { artifactDef } = useGitContext();

  const dispatch = useDispatch();

  const updateDefaultBranchState = useArtifactSelector(
    selectUpdateDefaultBranchState,
  );

  const updateDefaultBranch = useCallback(
    (branchName: string) => {
      if (artifactDef) {
        dispatch(
          gitArtifactActions.updateDefaultBranchInit({
            artifactDef,
            branchName,
          }),
        );
      }
    },
    [artifactDef, dispatch],
  );

  const useDefaultBranchCEValues = useDefaultBranchCE();

  return {
    ...useDefaultBranchCEValues,
    updateDefaultBranchLoading: updateDefaultBranchState?.loading ?? false,
    updateDefaultBranchError: updateDefaultBranchState?.error ?? null,
    updateDefaultBranch,
  };
};

export default useDefaultBranch;
