import {
  gitArtifactUIInitialState as gitArtifactUIInitialStateCE,
  gitArtifactAPIResponsesInitialState as gitArtifactAPIResponsesInitialStateCE,
} from "git/ce/store/helpers/initialState";

import type {
  GitArtifactAPIResponsesReduxState,
  GitArtifactUIReduxState,
} from "../types";

export const gitArtifactUIInitialState: GitArtifactUIReduxState = {
  ...gitArtifactUIInitialStateCE,

  cdDisableModalOpen: false,
  cdReconfigureModalOpen: false,
};

export const gitArtifactAPIResponsesInitialState: GitArtifactAPIResponsesReduxState =
  {
    ...gitArtifactAPIResponsesInitialStateCE,

    updateDefaultBranch: {
      loading: false,
      error: null,
    },
    toggleCD: {
      loading: false,
      error: null,
    },
    generateCDApiKey: {
      loading: false,
      error: null,
      value: null,
    },
  };
