import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export const openGenerateApprovalPageModal = () => {
  return {
    type: ReduxActionTypes.SHOW_GENERATE_APPROVAL_PAGE_MODAL,
  };
};

export const closeGenerateApprovalPageModal = () => {
  return {
    type: ReduxActionTypes.HIDE_GENERATE_APPROVAL_PAGE_MODAL,
  };
};
