import React from "react";
import { Flex, Slider } from "@appsmith/ads";

const MAX_CHUNK_SIZE = 4000;
const MIN_CHUNK_SIZE = 100;
const CHUNK_SIZE_STEP = 100;
const MAX_OVERLAP_SIZE = 800;
const MIN_OVERLAP_SIZE = 0;
const OVERLAP_SIZE_STEP = 10;

interface ChunkControlsProps {
  chunkSize?: number;
  overlapSize?: number;
  onChangeChunkSize: (value: number) => void;
  onChangeOverlapSize: (value: number) => void;
  isDisabled?: boolean;
}

export const RagChunkControls = (props: ChunkControlsProps) => {
  const {
    chunkSize,
    isDisabled = false,
    onChangeChunkSize,
    onChangeOverlapSize,
    overlapSize,
  } = props;

  return (
    <Flex flex="1" gap="spaces-7">
      <Slider
        defaultValue={chunkSize}
        getValueLabel={(tokens) => `${tokens} tokens`}
        isDisabled={isDisabled}
        label="Chunk Size"
        maxValue={MAX_CHUNK_SIZE}
        minValue={MIN_CHUNK_SIZE}
        onChangeEnd={onChangeChunkSize}
        step={CHUNK_SIZE_STEP}
      />
      <Slider
        defaultValue={overlapSize}
        getValueLabel={(tokens) => `${tokens} tokens`}
        isDisabled={isDisabled}
        label="Chunk Overlap"
        maxValue={MAX_OVERLAP_SIZE}
        minValue={MIN_OVERLAP_SIZE}
        onChangeEnd={onChangeOverlapSize}
        step={OVERLAP_SIZE_STEP}
      />
    </Flex>
  );
};
