import type { ModuleEntityItem } from "../../../types";
import { getActionConfig } from "pages/Editor/Explorer/Actions/helpers";

export const getQueryModuleEntityItemUrl = (item: ModuleEntityItem): string => {
  const config = getActionConfig(item.type);

  if (!config) {
    throw Error(`Cannot find url of plugin type ${item.type}`);
  }

  return config.getURL(item.moduleId, item.key, item.type);
};
