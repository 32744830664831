import { Button, Callout, Icon, Text } from "@appsmith/ads";
import { CopyButton } from "pages/Editor/gitSync/components/CopyButton";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  createMessage,
  GIT_CD_API_KEY_WARNING,
  GIT_CD_COPY_API_KEY,
} from "ee/constants/messages";
import noop from "lodash/noop";

const Container = styled.div`
  overflow: auto;
  margin-bottom: 16px;
`;

export const CopyContainer = styled.div`
  height: 36px;
  border: 1px solid var(--ads-v2-color-border);
  padding: 8px;
  box-sizing: border-box;
  border-radius: var(--ads-v2-border-radius);
  background-color: #fff;
  align-items: center;
  display: flex;
  margin-bottom: 4px;
  width: 100%;
`;

export const CopyText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  color: var(--ads-v2-color-fg);
  margin-right: 8px;
`;

interface CDApiKeyProps {
  compact?: boolean;
  ctaText?: string;
  descText?: string;
  isLoading: boolean;
  onClick: () => void;
  onUnmount: () => void;
  value: string | null;
}

function CDApiKey({
  compact = false,
  ctaText = "Generate API Key",
  descText = "Generate API key",
  isLoading = false,
  onClick = noop,
  onUnmount = noop,
  value = null,
}: CDApiKeyProps) {
  const maskedValue = useMemo(() => {
    if (!value) return "";

    const firstPart = value.slice(0, 4);

    return `${firstPart}*************************************`;
  }, [value]);

  useEffect(
    function onUnmountEffect() {
      return () => {
        onUnmount();
      };
    },
    [onUnmount],
  );

  return (
    <Container>
      <div className={compact ? "mb-1" : "mb-2"}>
        <Text renderAs="p">{descText}</Text>
      </div>
      {!value ? (
        <Button
          data-testid="t--cd-generate-api-key-btn"
          isLoading={isLoading}
          kind="secondary"
          onClick={onClick}
          size="md"
        >
          {ctaText}
        </Button>
      ) : (
        <>
          <CopyContainer>
            <Icon
              className="ml-1"
              color="var(--ads-v2-color-fg)"
              name="key-2-line"
              size="md"
            />
            <CopyText data-testid="t--cd-api-key-display">
              {maskedValue}
            </CopyText>
            <CopyButton
              style={{ marginLeft: "auto" }}
              testIdSuffix="cd-api-key-btn"
              tooltipMessage={createMessage(GIT_CD_COPY_API_KEY)}
              value={value}
            />
          </CopyContainer>
          <Callout className="mt-1" kind="warning">
            {createMessage(GIT_CD_API_KEY_WARNING)}
          </Callout>
        </>
      )}
    </Container>
  );
}

export default CDApiKey;
