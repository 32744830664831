import { usePrevious } from "@mantine/hooks";
import type { AppState } from "ee/reducers";
import type { DefaultRecordType } from "rc-table/lib/interface";
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
  type ForwardedRef,
} from "react";
import { setRagDocuments } from "ee/actions/ragDocumentsActions";
import type { ApiResponseError } from "api/types";
import { TEMP_DATASOURCE_ID } from "constants/Datasource";
import { CarbonButton } from "./CarbonButton";
import {
  Button,
  Flex,
  Icon,
  Link,
  Table,
  Spinner,
  Badge,
  type TableProps,
  toast,
  Tooltip,
} from "@appsmith/ads";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import type {
  RagDocumentsProps,
  DocumentStatus,
  DocumentLinkType,
  DocumentType,
  RagDocumentsRef,
} from "./types";
import {
  getIntegrationIcon,
  isoStringToLocalDate,
  mapDocumentsToTableData,
  getBadgeKind,
} from "./RagUtils";
import {
  deleteDocumentRequest,
  fetchDocuments,
  resyncDocumentRequest,
} from "./RagApiRequests";
import { isEqual } from "lodash";

const COLUMN_WIDTHS = {
  STATUS: 16,
  ICON: 24,
  RESYNC: 32,
  DELETE: 40,
} as const;

interface onDeleteDocumentParams {
  documentId: string;
  integrationType: DocumentType;
}

export const RagDocuments = forwardRef(
  (props: RagDocumentsProps, ref: ForwardedRef<RagDocumentsRef>) => {
    const {
      datasourceId,
      isDeletedAvailable = true,
      isImportDataAvailable = true,
      workspaceId,
    } = props;

    const dispatch = useDispatch();
    const documents = useSelector((state: AppState) =>
      datasourceId ? state.ai.ragDocuments?.[datasourceId] : undefined,
    );
    const prevDocuments = usePrevious(documents);
    const [tableData, setTableData] =
      useState<TableProps<DefaultRecordType>["data"]>();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [deletingId, setDeletingId] = useState<string | null>(null);
    const [resyncingId, setResyncingId] = useState<string | null>(null);
    const [isModalClosed, setIsModalClosed] = useState<boolean | null>(null);

    const onDeleteDocument = useCallback(
      async ({ documentId, integrationType }: onDeleteDocumentParams) => {
        if (!datasourceId) return;

        setDeletingId(documentId);

        try {
          await deleteDocumentRequest(
            datasourceId,
            documentId,
            integrationType,
          ).catch(() => {
            setDeletingId(null);
          });

          const newDocuments = documents?.filter(
            (item) => item.ragId !== documentId,
          );
          const newTableData = tableData?.filter(
            (item) => item["col8"] !== documentId,
          );

          dispatch(setRagDocuments({ [datasourceId]: newDocuments || [] }));
          setTableData(newTableData);
          setDeletingId(null);
        } catch (error) {
          toast.show((error as ApiResponseError).message, { kind: "error" });
          setDeletingId(null);
        }
      },
      [datasourceId, dispatch, documents, tableData],
    );

    const onResyncDocument = useCallback(
      async (documentId) => {
        if (!datasourceId) return;

        setResyncingId(documentId);

        try {
          const resyncedDocument = await resyncDocumentRequest(
            datasourceId,
            documentId,
          );

          const newDocuments = documents?.map((ragDocument) =>
            ragDocument.ragId === documentId ? resyncedDocument : ragDocument,
          );

          dispatch(setRagDocuments({ [datasourceId]: newDocuments || [] }));
          setTableData(mapDocumentsToTableData(newDocuments || []));

          setResyncingId(null);
        } catch (error) {
          toast.show((error as ApiResponseError).message, { kind: "error" });
          setResyncingId(null);
        }
      },
      [datasourceId, dispatch, documents],
    );

    const defaultColumns = useMemo(
      () => [
        {
          dataIndex: "col1",
          render: (value: DocumentStatus) => (
            <Badge kind={getBadgeKind(value)} />
          ),
          width: COLUMN_WIDTHS.STATUS,
        },
        {
          dataIndex: "col2",
          width: COLUMN_WIDTHS.ICON,
          render: (value: DocumentType) => (
            <Icon name={getIntegrationIcon(value)} size="md" />
          ),
        },
        {
          title: "Name",
          dataIndex: "col3",
          render: (value: DocumentLinkType) => {
            if (!value.url) return value.name;

            return (
              <Link className={styles.link} target="_blank" to={value.url}>
                {value.name}
              </Link>
            );
          },
          sortBy: "name",
        },
        {
          title: "Type",
          dataIndex: "col4",
        },
        {
          title: "Size",
          dataIndex: "col5",
        },
        {
          title: "Chunk overlap",
          dataIndex: "col6",
        },
        {
          title: "Chunk size",
          dataIndex: "col7",
        },
        {
          title: "Last update",
          dataIndex: "col8",
          render: (value: string) => isoStringToLocalDate(value),
        },
        // TODO: https://github.com/appsmithorg/appsmith/issues/39347
        // {
        //   dataIndex: "col9",
        //   width: COLUMN_WIDTHS.RESYNC,
        //   render: (value: string) => (
        //     <Button
        //       isDisabled={deletingId !== null || resyncingId !== null}
        //       isIconButton
        //       isLoading={resyncingId === value}
        //       kind="secondary"
        //       onClick={async () => onResyncDocument(value)}
        //       startIcon="refresh"
        //     />
        //   ),
        //   isSortable: false,
        // },
      ],
      [deletingId, onResyncDocument, resyncingId],
    );

    const onFetchDocuments = useCallback(
      async (datasourceId: string) => {
        setIsDataLoading(true);

        try {
          const { documents } = await fetchDocuments(datasourceId);

          dispatch(setRagDocuments({ [datasourceId]: documents }));
          setIsDataLoading(false);
        } catch (error) {
          toast.show((error as ApiResponseError).message, { kind: "error" });
          setIsDataLoading(false);
        }
      },
      [dispatch],
    );

    const onRefreshDocuments = useCallback(() => {
      if (!datasourceId) return;

      onFetchDocuments(datasourceId);
    }, [datasourceId, onFetchDocuments]);

    useImperativeHandle(
      ref,
      () => ({
        refreshDocuments: () => onRefreshDocuments(),
      }),
      [onRefreshDocuments],
    );

    const columns = useMemo(() => {
      if (isDeletedAvailable) {
        return [
          ...defaultColumns,
          {
            dataIndex: "col10",
            width: COLUMN_WIDTHS.DELETE,
            render: (value: string) => (
              <Button
                isDisabled={deletingId !== null || resyncingId !== null}
                isIconButton
                isLoading={deletingId === value}
                kind="secondary"
                onClick={async () =>
                  onDeleteDocument(value as unknown as onDeleteDocumentParams)
                }
                startIcon="delete"
              />
            ),
            isSortable: false,
          },
        ];
      }

      return defaultColumns;
    }, [
      isDeletedAvailable,
      defaultColumns,
      deletingId,
      resyncingId,
      onDeleteDocument,
    ]);

    useEffect(
      function setDocumentsFromState() {
        if (!isEqual(prevDocuments, documents)) {
          setTableData(mapDocumentsToTableData(documents));
        }
      },
      [documents, prevDocuments],
    );

    useEffect(
      function loadDocuments() {
        if (
          datasourceId &&
          datasourceId !== TEMP_DATASOURCE_ID &&
          (!documents || isModalClosed === false)
        ) {
          onFetchDocuments(datasourceId);

          setIsModalClosed(null);
        }
      },
      // documents should not be used as a dependency, as it is a mutable array and its usage may lead to unexpected re-renders.
      [datasourceId, isModalClosed, onFetchDocuments],
    );

    const renderContent = () => {
      if (!datasourceId || !workspaceId) return null;

      return (
        <Flex flex="1" flexDirection="column" gap="spaces-4">
          {isImportDataAvailable && (
            <Flex alignItems="center" justifyContent="space-between">
              <CarbonButton
                datasourceId={datasourceId}
                onModalStateChange={setIsModalClosed}
                workspaceId={workspaceId}
              />
              <Tooltip content="Refresh documents" placement="left">
                <Button
                  isIconButton
                  kind="secondary"
                  onClick={onRefreshDocuments}
                  size="md"
                  startIcon="restart-line"
                />
              </Tooltip>
            </Flex>
          )}

          {isDataLoading && <Spinner className={styles.spinner} size="lg" />}

          {!isDataLoading && (
            <Table
              className={styles.table}
              columns={columns}
              data={tableData}
              isSortable
            />
          )}
        </Flex>
      );
    };

    return renderContent();
  },
);
