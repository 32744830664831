import {
  createMessage,
  GIT_CD_DISABLE_CD,
  GIT_CD_LAST_DEPLOYMENT,
} from "ee/constants/messages";
import { Button, Icon, Text } from "@appsmith/ads";
import React, { useCallback } from "react";
import { howMuchTimeBeforeText } from "utils/helpers";
import noop from "lodash/noop";
import type { GitSettingsTab } from "git/constants/enums";

interface CDDisableZoneProps {
  defaultBranch: string | null;
  artifactLastDeployedAt: string | null;
  toggleSettingsModal: (
    open: boolean,
    tab?: keyof typeof GitSettingsTab,
  ) => void;
  toggleCDDisableModal: (open: boolean) => void;
}

function CDDisableZone({
  artifactLastDeployedAt = null,
  defaultBranch = null,
  toggleCDDisableModal = noop,
  toggleSettingsModal = noop,
}: CDDisableZoneProps) {
  const handleClickOnDisable = useCallback(() => {
    toggleSettingsModal(false);
    toggleCDDisableModal(true);
  }, [toggleCDDisableModal, toggleSettingsModal]);

  const lastDeployedAtMsg = artifactLastDeployedAt
    ? `${howMuchTimeBeforeText(artifactLastDeployedAt, {
        lessThanAMinute: true,
      })} ago`
    : "";

  return (
    <div className="flex items-center">
      <div className="flex-1">
        <div className="flex items-center mb-2">
          {/*@ts-expect-error Numbers are accepted but types are not defined*/}
          <Icon className="mr-2" name="git-commit" size={16} />
          <Text className="!font-semibold" kind="heading-s" renderAs="h3">
            {createMessage(GIT_CD_LAST_DEPLOYMENT)}
          </Text>
        </div>
        {artifactLastDeployedAt && (
          <Text renderAs="p">
            {lastDeployedAtMsg} | {defaultBranch}
          </Text>
        )}
      </div>
      <div className="shrink-0">
        <Button
          data-testid="t--cd-disable-btn"
          kind="error"
          onClick={handleClickOnDisable}
          size="md"
        >
          {createMessage(GIT_CD_DISABLE_CD)}
        </Button>
      </div>
    </div>
  );
}

export default CDDisableZone;
