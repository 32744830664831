export * from "ce/actions/helpers";
import type { EventLocation } from "ee/utils/analyticsUtilTypes";
import type { ActionParentEntityTypeInterface } from "ee/entities/Engine/actionHelpers";
import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";
import {
  createHITLQuery,
  createWorkflowAPIAction,
  createWorkflowJSCollection,
  createWorkflowQueryAction,
  createWorkflowQueryInApp,
  saveJSObjectNameForWorkflows,
  saveWorkflowActionName,
} from "ee/actions/workflowActions";
import {
  createNewQueryBasedOnParentEntity as CE_createNewQueryBasedOnParentEntity,
  createNewAPIBasedOnParentEntity as CE_createNewAPIBasedOnParentEntity,
  createNewJSCollectionBasedOnParentEntity as CE_createNewJSCollectionBasedOnParentEntity,
  saveActionNameBasedOnIdeType as CE_saveActionNameBasedOnIdeType,
  saveJSObjectNameBasedOnIdeType as CE_saveJSObjectNameBasedOnIdeType,
} from "ce/actions/helpers";
import {
  createNewAPIActionForPackage,
  createNewJSCollectionForPackage,
  createNewQueryActionForPackage,
  createQueryModule,
  saveActionNameForPackage,
  saveJSObjectNameForPackage,
} from "./moduleActions";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import { IDE_TYPE, type IDEType } from "ee/IDE/Interfaces/IDETypes";

export const createNewQueryBasedOnParentEntity = (
  entityId: string,
  from: EventLocation,
  dsId: string,
  parentEntityType: ActionParentEntityTypeInterface = ActionParentEntityType.PAGE,
) => {
  switch (parentEntityType) {
    case ActionParentEntityType.WORKFLOW:
      return createWorkflowQueryAction(entityId, from, dsId);
    case ActionParentEntityType.MODULE:
      return createNewQueryActionForPackage(entityId, from, dsId);
    default:
      return CE_createNewQueryBasedOnParentEntity(entityId, from, dsId);
  }
};

export const createNewAPIBasedOnParentEntity = (
  entityId: string,
  from: EventLocation,
  apiType?: string,
  parentEntityType: ActionParentEntityTypeInterface = ActionParentEntityType.PAGE,
) => {
  switch (parentEntityType) {
    case ActionParentEntityType.WORKFLOW:
      return createWorkflowAPIAction(entityId, from, apiType);
    case ActionParentEntityType.MODULE:
      return createNewAPIActionForPackage(entityId, from, apiType);
    default:
      return CE_createNewAPIBasedOnParentEntity(entityId, from, apiType);
  }
};

export const createNewJSCollectionBasedOnParentEntity = (
  entityId: string,
  from: EventLocation,
  parentEntityType: ActionParentEntityTypeInterface = ActionParentEntityType.PAGE,
) => {
  switch (parentEntityType) {
    case ActionParentEntityType.WORKFLOW:
      return createWorkflowJSCollection(entityId, from);
    case ActionParentEntityType.MODULE:
      return createNewJSCollectionForPackage(entityId, from);
    default:
      return CE_createNewJSCollectionBasedOnParentEntity(entityId, from);
  }
};

export const saveActionNameBasedOnIdeType = (
  id: string,
  name: string,
  ideType: IDEType = IDE_TYPE.App,
) => {
  switch (ideType) {
    case IDE_TYPE.Workflow:
      return saveWorkflowActionName({ id, name });
    case IDE_TYPE.Package:
      return saveActionNameForPackage({ id, name });
    default:
      return CE_saveActionNameBasedOnIdeType(id, name);
  }
};

export const saveJSObjectNameBasedOnIdeType = (
  id: string,
  name: string,
  ideType: IDEType = IDE_TYPE.App,
): ReduxAction<{ id: string; name: string }> => {
  switch (ideType) {
    case IDE_TYPE.Workflow:
      return saveJSObjectNameForWorkflows({ id, name });
    case IDE_TYPE.Package:
      return saveJSObjectNameForPackage({ id, name });
    default:
      return CE_saveJSObjectNameBasedOnIdeType(id, name);
  }
};

export const createNewApiActionBasedOnIdeType = (
  ideType: IDEType,
  editorId: string,
  parentEntityId: string,
  parentEntityType: ActionParentEntityTypeInterface,
  apiType: string, // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (ideType === IDE_TYPE.Package && !parentEntityId && editorId) {
    return createQueryModule({
      type: MODULE_TYPE.QUERY,
      from: "API_PANE",
      packageId: editorId,
      apiType,
    });
  } else if (parentEntityId) {
    return createNewAPIBasedOnParentEntity(
      parentEntityId,
      "API_PANE",
      apiType,
      parentEntityType,
    );
  }
};

export const createWorkflowExecutionActionBasedOnParentEntityType = (
  entityId: string,
  location: EventLocation,
  parentEntityType: ActionParentEntityTypeInterface = ActionParentEntityType.PAGE,
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (parentEntityType === ActionParentEntityType.PAGE) {
    return createWorkflowQueryInApp(entityId, location);
  } else if (parentEntityType === ActionParentEntityType.WORKFLOW) {
    return createHITLQuery(entityId, location);
  }
};
