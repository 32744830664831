import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getCurrentWorkflowState } from "../utils";
import { EditorState } from "IDE/enums";

export const useCurrentWorkflowState = () => {
  const [appState, setAppState] = useState(EditorState.EDITOR);
  const { pathname } = useLocation();

  useEffect(
    function setCurrentWorkflowState() {
      setAppState(getCurrentWorkflowState(pathname));
    },
    [pathname],
  );

  return appState;
};
