import { jsCollectionIdURL } from "ee/RouteBuilder";
import { PluginType, type Plugin } from "entities/Plugin";
import { resolveActionURL } from "pages/Editor/Explorer/Actions/helpers";

interface GetUrlProps {
  baseId: string;
  plugin: Plugin;
  baseModuleId: string;
}

export const getEntityURL = ({ baseId, baseModuleId, plugin }: GetUrlProps) => {
  if (plugin.type === PluginType.JS) {
    return jsCollectionIdURL({
      baseModuleId,
      baseCollectionId: baseId,
    });
  }

  return resolveActionURL({
    pluginType: plugin.type,
    plugin,
    baseId,
    baseParentEntityId: baseModuleId,
  });
};
