export * from "ce/pages/AppIDE/layout/components/Header/useLibraryHeaderTitle";
import useCELibraryHeaderTitle from "ce/pages/AppIDE/layout/components/Header/useLibraryHeaderTitle";

function useLibraryHeaderTitle() {
  const ceLibraryHeaderTitle = useCELibraryHeaderTitle();
  // const showQueryModule = useSelector(getShowQueryModule);

  // if (showQueryModule) return "";

  return ceLibraryHeaderTitle;
}

export default useLibraryHeaderTitle;
